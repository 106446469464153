import enLocale from 'element-ui/lib/locale/lang/en'

const lang = {
  ...enLocale,
  common: {
    alertPrompt: 'Attention',
    requestCheckConnected: 'The server is busy, please try again later!',
    updateInstruction: 'Update Instructions',
    operation: 'Action',
    yes: 'Yes',
    no: 'No',
    sort: 'Sort',
    copy: 'Copy',
    confirm: 'Confirm',
    cancel: 'Cancel',
    save: 'Save',
    detail: 'Detail',
    viewDetail: 'View Detail',
    add: 'Add',
    create: 'Create',
    import: 'Import',
    view: 'View',
    set: 'Set',
    delete: 'Delete',
    deleteAll: 'Delete All',
    publish: 'Publish',
    preview: 'Preview',
    imageUploadTip: 'The picture size should be within {size}',
    attahmentUploadTip: 'The attachment size should be within {size}',
    photos: 'Photos',
    select: 'Select',
    clear: 'Clear',
    back: 'Back',
    stop: 'Stop',
    edit: 'Edit',
    change: 'Change',
    start: 'Start',
    hour: 'Hour',
    minute: 'Minute(s)',
    day: 'Day',
    noSupportTips: 'The current version does not support this function. If you need to use it, please upgrade the version. If you have any questions, please contact customer service.',
    valid: 'Valid',
    inValid: 'Invalid',
    timeOut: 'Timeout',
    enable: 'Enable',
    close: 'Close',
    all: 'All',
    to: 'To',
    more: 'More',
    send: 'Send',
    ok: 'Ok',
    submit: 'Submit',
    copyrightDesc: 'Copyright © 2024 SAMEX Systems Limited. All Rights Reserved',
    seleted: 'Seleted',
    pleaseSelect: 'Please select',
    startTime: 'Start Time',
    endTime: 'End Time',
    timeTo: 'To',
    pageTotal: 'Total',
    pageTotalRecord: 'record',
    pageTotalRecords: 'records',
    nightMode: 'Black Theme',
    noData: 'No data',

    // 短信错误信息、发送验证码失败的提示
    ERR_NOTICE_0000: 'Sending failed',
    ERR_NOTICE_0001: ' is not a valid mobile number',
    ERR_NOTICE_0002: 'Operator throttling',
    CFG_NOTICE_0003: 'Not have enough quantity or not enable sms',
    CFG_NOTICE_0004: 'Not found phone or not found areacode',
    CFG_NOTICE_0005: 'Permission to send an SMS has not been enabled for the region',
  },
  guideBox: {
    nextStep: 'Next',
    preStep: 'Previous Step',
    androidDownload: 'Android ',
    iosDownload: 'iOS ',
    guideFinish: 'Finish',
    guideAccountRepeatedly: 'Continue to create account',
    guidePointSrepeatedly: 'Continue to create inspection point',
  },
  webLayout: {
    webSiteHome: 'Website Homepage',
    locCountLabel: 'Inspection Points',
    cardExpireDateLabel: 'Expire Date',
    onLineService: 'Customer Service',
    help: 'Help',
    releaseHistory: 'Version History',
    showGuideName: 'Show Wizard',
    ViewTutorialVideos: 'View Tutorial Videos',
    logOut: 'Sign Out',
    navHome: 'Home',
    navInspection: 'Inspection',
    navSafetyIncident: 'Safety',
    navProperty: 'Property Entity',
    navAccount: 'Account Detail',
    navNotification: 'Notification',
    navApp: 'APP',
    navSystem: 'System Settings',
    navSSO: 'SSO Settings',
    navCommonReasonManagement: 'Frequently Used Reason',
    navVisitorQuestionTmpl: 'Questionnaire for Visitor',
    navSla: 'SLA Setting',
    vipExperience: 'Experience',
    vipGreen: 'Green',
    vipSilver: 'Silver',
    vipGold: 'Gold',
    androidDownload: 'Android ',
    iosDownload: 'iOS ',

    collapseMenu: 'Collapse the menu bar',
    propertyEntity: 'Property Entity',
    entityDetail: 'Property Entity Detail',
    userAccounts: 'User Accounts',
    safetyGuard: 'Safety Guard',
    authorizationManagement: 'Authorization Management',
    workGroups: 'Work Groups',
    accessGroups: 'Access Groups',
    inspectionPoints: 'Inspection Points',
    holidayManagement: 'Holiday Management',
    assetLedger: 'Asset Ledger',
    iotSensors: 'IoT Sensors',
    inspectionPlan: 'Inspection Plan',
    createInspectionPlan: 'Create Inspection Plan',
    inspectionPlanManagement: 'Inspection Plan Management',
    sopManagement: 'SOP Management',
    workOrderTemplate: 'Work Order Template',
    workOrderTemplateManagement: 'Work Order Template Management',
    punchInTemplate: 'Punch-in Template',
    signInTemplateManagement: 'Punch-in Template Management',
    monitorTemplate: 'IoT Monitoring',
    monitorTemplateManagement: 'IoT Monitoring Setup',
    historicalRecords: 'Historical Records',
    inspectionOrderRecords: 'Inspection Orders',
    workOrderRecords: 'Work Orders',
    signInRecord: 'Punch-in Records',
    iotSensorList: 'IoT Readings',
    incidentOrderRecords: 'Incident Reports',
    dataMeterReadings: 'Data / Meter Readings',
    alertsHistory: 'Alert Records',
    visitorManagement: 'Visitor Management',
    receptionEntranceManagement: 'Entrance Management',
    visitorAppointments: 'Visitor Appointments',
    visitorVisitRecords: 'Visitor Records',
    supportingFunctions: 'Supporting Functions',
    dutyLogbook: 'Duty Logbook',
    documentManagement: 'Document Management',
    documentUpload: 'Document Library',
    documentAccessRecords: 'Document Access Records',
    myDocuments: 'My Documents',
    statisticsAnalysis: 'Statistics & Analysis',
    reportCenter: 'Report Center',
    inspectionEfficiencyDashboard: 'Inspection Efficiency Dashboard',
    incidentReportDashboard: 'Incident Report Dashboard',
    workOrderResponseTimeDashboard: 'Work Order Response Time Dashboard',
    abnormalityDashboard: 'Abnormality Locations Dashboard',
    humanEfficiencyDashboard: 'Human Efficiency Dashboard',
    inventoryMaterial: 'Inventory',
    inventoryManagement: 'Inventory Management',
    materialUsageRecord: 'Material Usage',
    switchPropertySuccess: 'The Property Entity is switched successfully！',
    switchLangSuccess: 'The Language is switched successfully！',
    rentalManagement: 'Lend & Return Management',
    rentPropertyList: 'Lendable Asset List',
    lendReturn: 'Lend / Return',
    disposalOrder: 'Disposal Order',
  },
  login: {
    loginBannerHomeLabel: 'Internet of Things (IoT) sensor module',
    loginBannerHomeTitle1: 'Smart Perception, Future Control,',
    loginBannerHomeTitle2: 'Facilities and Equipment in Your Hands!',
    loginBannerHomeLi1: 'Real time status monitoring',
    loginBannerHomeLi2: 'Intelligent inspection alarm',
    loginBannerHomeLi3: 'Real time work order dispatch',

    loginBannerInventoryLabel: 'Inventory management module',
    loginBannerInventoryTitle1: 'From Trivial to Critical: Material Management Excellence,',
    loginBannerInventoryTitle2: 'The Backbone of Core Operations!',
    loginBannerInventoryLi1: 'Seamless requests',
    loginBannerInventoryLi2: 'Real-time inventory control',
    loginBannerInventoryLi3: 'Flexible allocation',

    loginBannerVisitorLabel: 'Visitor management module',
    loginBannerVisitorTitle1: 'Zero Paper, New Experience!',
    loginBannerVisitorTitle2: 'Safe, Efficient, and Effortless Visitor Access!',
    loginBannerVisitorLi1: 'Paperless registration.',
    loginBannerVisitorLi2: 'No APP installation required.',
    loginBannerVisitorLi3: 'Security control, orderly entry.',

    loginTitle: 'Professional Inspection Cloud Platform',
    logIn: 'Login',
    contactUs: 'Contact Us',
    phoneLogin: 'Mobile Login',
    configPhone: 'Phone No.',
    accountPass: 'Password',
    accLogin: 'Account Login',
    peshortName: 'Property Entity Short Name',
    accountId: 'Account',
    hybrid: 'Azure AD',
    email: 'Third-Party Account',
    vercodeTips: 'Image verification code',
    freeregnow: 'Register Now',
    forgotPass: 'Forgot Password?',
    otherLoginMethods: 'Other Login Methods',

    // 登录页面的联系方式
    labelSg: 'Singapore:',
    labelAu: 'Australia:',
    labelHk: 'Hong Kong:',
    labelTw: 'Taiwan:',
    labelCn: 'China Mainland:',

    loginAreaCodeTips: 'Please select the area code',
    loginPhoneTips: 'Phone No. is a mandatory field',
    loginPasswordTips: 'Password is a mandatory field',
    loginPeabbrTips: 'Property entity abbreviation is a mandatory field',
    loginUserAccountTips: 'Account is a mandatory field',
    loginHybridAccountTips: 'Account is a mandatory field',
    loginHybridImgCodeTips: 'Verification code is a mandatory field',

    webSiteVersion: 'Your account belongs to another server ({anotherSite}), we have redirected you to the new address, please log in again',
  },
  register: {
    welcomeReg: 'Welcome to Registration',
    chinesePhoneTips: 'Cellphone Number',
    configPass: 'Password',
    rePass: 'Repeat Password',
    phoneCode: 'SMS Code',
    regSuccessMsg: "Registration is successful, click 'OK' to log in now",
    immReg: 'Register Now',
    sendCode: 'Send Code',
    inputCodePlh: 'Please enter the verification code',
    inputCodeError: 'SMS Code is a mandatory field',
    sendCodeBtn: 'Send SMS Code',
    sendCodeBtning: '{seconds} Seconds',
    phoneCodeTips: "Click 'Send SMS Code' for verification code",
    rePassTips: 'Input Same Password Again',
    configNewPassFormat: 'Please enter 6-20 digits, letters, or special symbols.',
    configNewPassFormatTip: 'Please enter 6-20 digits, letters, or special symbols. Special symbols only @#&*_',
    tips_402001: 'This account has been registered, please log in directly',
    existAccountTip: 'This account has already been registered. Do you want to log in?',
    goToLoginLabel: 'Login',
    forgetPwdTip: 'If you forgot your password, you can go to the Forgot Password page to reset it',
    retrievePwdLabel: 'Reset Password',
    goUseFlowTip: 'You can also go to the "Use Flow" page to learn and operate',
    useFlowLabel: 'Use Flow',
  },
  forgot: {
    loginTitle: 'Professional Inspection Cloud Platform',
    forgotPass: 'Forgot Password?',
    verAcc: 'Verify Account',
    modPass: 'Change Password',
    resComp: 'Reset Completed',
    configPhone: 'Phone No.',
    verCode: 'Image Code',
    phoneCode: 'SMS Code',
    nextStep: 'Next',
    verCodeTips: 'Image verification code',
    phoneCodeTips: 'Phone verification code',
    phoneIsNotExist: 'The number does not exist',
    correctImageCode: 'Verification code is a mandatory field',
    correctCode: 'Incorrect verification code, please input again, or resend.',
    sendCodeSuccess: 'SMS code has been sent to your phone.',
    newPass: 'New Password',
    configNewPassformat: 'Please enter 6-20 digits, letters, or special symbols. Special symbols only @#&*_',
    repNewPassTips: 'Please enter a 6-20 digit string password',
    repNewPass: 'Repeat New Password',
    passIsConsistent: 'Inconsistent password entered',
    modSuccessMsg: 'Your password has been reset. Please remember your new password.',
    modSuccessMsgJumpMsg: 'In {seconds} seconds, you will be redirected to the {loginPage}.',
    loginPage: 'Login Page',
    contactPhone: 'Online customer service +852 8111 4290',
    home: 'Home',
    login: 'Login',
  },
  forgotAccount: {
    peShortName: 'Property Entity Short Name',
    peShortNameTips: 'Please Enter the Property Entity Abbreviation',
    accountId: 'Account',
    accountIdTips: 'Please Enter the User Account',
    verCode: 'Image Code',
    verCodeTips: 'Image verification code',
    peAdminPhone: 'Property Manager Phone',
    peAdminPhoneTips: 'Please Enter the Data Administrator Phone Number',
    phoneNumError: 'The phone number is incorrect',
    correctImageCode: 'Verification code is a mandatory field',
    accmodSuccessMsg: 'Password reset succeeded. The new password has been sent to the number：',
  },
  selectFromTemplate: {
    selMulTemTit: 'Select from Template',
    enterSearch: 'Template name',
  },
  saveOptionsTemplate: {
    saveMulTem: 'Save Options as Template',
    temName: 'Template Name',
    checkSelectVal: 'Please add options',
    checkTemNamePlh: 'Please enter a template name',
    checkTemName: 'Template name is a mandatory field',
    saveSuccess: 'The Options Template has been saved successfully!',
  },
  home: {
    homeTitle: 'Overview',
    setting: 'Setting',
    allProperty: 'All Properties',
    homeAccountData: 'Summary',
    homeAccountDataAll: 'All Property Entities',
    homeAccountDataCurrent: '{property}',
    proentity: 'Property Entity',
    homeAccount: 'User Account',
    homeLoc: 'Inspection Point',
    instask: 'Inspection Plan',
    woTemplate: 'Work Order Template',
    homeRcWoTit: 'Inspection Order Execution',
    homeWoTit: 'Work Order Execution',
    homeIrTit: 'Incident Report',

    homeCancel: 'Cancelled',
    homeCompleted: 'Completed',
    homeExecuting: 'In Progress',
    homeTimeout: 'Timeout',
    homeToExecuted: 'Pending',
    homeShelve: 'On Hold',
    homeReviewed: 'Reviewed',

    chartTitle: 'Chart',
    homeRcTaskTit: 'Inspection Order Execution',
    homeWoTaskTit: 'Work Order Execution',
    homePeIrTit: 'Incident Report',
    homeAbLocTit: 'Number of Abnormal Inspection Points',
    homeHrRcTit: 'Inspection by Personnel',
    noData: 'No data',
    meter: 'Meter',
    fold: 'Fold',
    unfold: 'Unfold',
    switchChartTip: 'Switch Chart',
    startDate: 'Start Date',
    endDate: 'End Date',

    just: 'Just',
    yearsAgo: 'Years ago',
    monthsAgo: 'Months ago',
    weeksAgo: 'Weeks ago',
    daysAgo: 'Days ago',
    hoursAgo: 'Hours ago',
    minutesAgo: 'Minutes ago',

    yearAgo: 'Year ago',
    monthAgo: 'Month ago',
    weekAgo: 'Week ago',
    dayAgo: 'Day ago',
    hourAgo: 'Hour ago',
    minuteAgo: 'Minute ago',

    nameAxis: 'Name',
    amountAxis: 'Amount',
    percentageAxis: 'Percentage',

    viewTutorial: 'View Tutorial',
    tutorialStep: 'Tutorial{num}',
    functionStep: 'Function{num}',
    tutorialIsEmpty: 'No Tutorial',
    tutorialTitle: 'Learn Basic Tutorials',
    tutorialSubtitle: 'With just {num} steps, you can easily master the secrets of patrol and inspection management',
    tutorialOtherTitle: 'Other Functions',
    tutorialOtherSubtitle: 'Explore More Practical Functions',
    tutorialPopTips: 'The system has detected that your account is still in the novice starter period, so in order to help you with the core functions, this guide will automatically appear after each login until you successfully complete 10 inspections.',
    alwaysAppear: 'Why do I always appear?',
  },
  homeConfigDashboard: {
    configDashboard: 'Setting',
    meterConfig: 'Meter Configuration',
    addMeter: 'Add Meter',
    meters: 'Meters',
    meterSearch: 'Search by Monitoring Rule Name',
    refreshRate: 'Refresh rate {number} Minute(s)',
    noRefresh: 'If the value is 0, no refresh',
    chartConfig: 'Chart Configuration',
    chartDisplayTime: 'Chart Display Time',
    nearlySevenDays: 'Last 7 Days',
    nearlyThirtyDays: 'Last 30 Days',
    nearlyNinetyDays: 'Last 90 Days',
    thisMonth: 'Month-to-Date',
    lastMonth: 'Last Month',
    thisQuarter: 'Quarter-to-Date',
    lastQuarter: 'Last Quarter',
    thisYear: 'Year-to-Date',
    prevYear: 'Last Year',
  },
  homeAccountDetail: {
    peDesc: 'Property Entity Name',
    accountId: 'Username',
    accountName: 'Account Owner',
    accountRole: 'Role',
    authGroup: 'Access Groups',
    task: 'Task',
    intervalToCreateTempTask: 'Each {content} To Generate Inspection Order',
    tempTask: 'Ad-hoc Patrol Order',
    nextInstime: 'Next Inspection Time',
    notSet: 'Not Set',
    unsPec: 'Unspecified',
    status: 'Status',
    suswo: 'Continuous Work Order',
  },
  propertyList: {
    proEntity: 'Property Entity',
    proEntityTips: 'You may have different inspection orders, work orders, and different setups for each property entity.',
    searchOfficeName: 'Search by Property Entity Name or Abbreviation',
    current: 'Current',
    switchProperty: 'Switch to this Property Entity',
    numberLimit: 'It has reached the maximum quantity that can be created in the current version. If you need to increase the maximum quantity, please upgrade the version. If you have any questions, please contact customer service.',
    emptyTips: 'No relevant property entity found.',
    nameAsc: 'Ascending Order by Name',
    nameDesc: 'Descending Order by Name',
    createdTimeAsc: 'Ascending Order by Creation Time',
    createdTimeDesc: 'Descending Order by Creation Time',
    basinfo: 'Entity Detail',
    homeAccount: 'User Account',
    homeLoc: 'Inspection Point',
    comitemman: 'SOP Management',
    instask: 'Inspection Plan',
    inslist: 'Inspection Order',
    increport: 'Incident Report',
    publishNotice: 'Notification',
  },
  propertyCreate: {
    createPe: 'Create Property Entity',
    imageUploadShowTip: 'The recommended size is 304px*200px and the maximum size is {size}',
    peabAccountTips: 'The short name of the property entity should fill in when you log in is: ',
    loginMustInput: 'This is a unique property entity identifier helps differentiate between customers.',
    alertPhoneTips: 'Emergency telephone number for inspector calling for help.',
    correctPeName: 'Entity Name is a mandatory field',
    peNameEmptyTips: 'Abbreviation is a mandatory field',
    peNameFormat: '4~20 characters, only letters and numbers can be used',
    correctalTerphone: 'Please input the correct alertphone',
    officialPropertyNameTip: 'Your Official Property Entity Name:',
    entityName: 'Entity Name:',
    abbreviation: 'Abbreviation:',
    industry: 'Property:',
    address: 'Address:',
    emergencyContact: 'Emergency Contact:',
    photoWatermarked: 'Whether the APP inspection point photos are watermarked.',
    uploadPhoto: 'Upload Photo:',
  },
  propertyDetail: {
    basinfo: 'Entity Detail',
    imageUploadShowTip: 'The recommended size is 304px*200px and the maximum size is {size}',
    peabAccountTips: 'The short name of the property entity should fill in when you log in is: ',
    editSuccess: 'Property Entity Detail have been saved!',
    officialPropertyNameTip: 'Your Official Property Entity Name:',
    entityName: 'Entity Name:',
    abbreviation: 'Entity Abbreviation:',
    industry: 'Property:',
    address: 'Address:',
    emergencyContact: 'Emergency Contact:',
    photoWatermarked: 'Automatically add watermark to photo taken for inpsection/work order.',
    uploadPhoto: 'Upload Photo:',
  },
  account: {
    mainAccount: 'User Accounts',
    mainAccountTips: 'All 313FM users need to be assigned with a user account for login, all 313 tasks and access will be granted to the user, or work group make of multiple users. User account can be created based on individual, job roles or shifts.',
    accountId: 'Username',
    accountName: 'Account Owner',
    accountRole: 'Role',
    authGroup: 'Access Groups',
    dataAdmin: 'Data Administrator',
    pemgr: 'Property Manager',
    generalUser: 'General User',
    confirmDelete: 'Confirm to delete the record?',
    accountQueryTips: 'Search by Username or Account Owner',
    emptyTips: 'No relevant user account found.',
    deleteSuccess: 'The User Account has been deleted successfully!',
  },
  accountCreate: {
    newAccount: 'Create User Account',
    editAccount: 'Edit User Account',
    loginMustInputAcc: 'It needs to be filled in when logging in,unique identification of account number,and it is recommended to use English & Numerals',
    accountIdEmptyTips: 'Username is a mandatory field',
    accountNameEmptyTips: 'Account Owner is a mandatory field',
    accountIdOver: 'Account cannot exceed 20 words',
    accountNameOver: 'Name cannot exceed 20 words',
    useAccountPhoneErrmsg: 'Only numbers can be entered',
    checkPasswordeError: 'Two passwords mismatch',
    useAccountPhoneTips: 'Message: Mobile No. for receiving SMS Notifications, default number will be the mobile no. used for account registration.',
    thirdpartyAccount: 'Azure AD Corporate user account for Single Sign-On',
    accountPassMsg: 'Leave blank by default 123456',
    accountPassMsg2: 'Leave blank leaves password unchanged',
    dataAdmin: 'Data Administrator',
    pemgr: 'Property Manager',
    dataManTips: "Only the 'Data Administrator' has the authority to maintain basic data.",
    peManTips: "Property Entity Managers can bind NFC tag and Bluetooth beacon.",
    accountId: 'Username',
    accountName: 'Account Owner',
    selPhoneNum: 'Mobile No.',
    email: 'Third-Party Account',
    accountPass: 'Password',
    checkPassword: 'Confirm Password',
    checkPasswordPh: 'Repeat same password',
    createSuccess: 'The User Account has been added successfully!',
    editSuccess: 'The User Account has been edited successfully!',
    numberLimit: 'It has reached the maximum quantity that can be created in the current version. If you need to increase the maximum quantity, please upgrade the version. If you have any questions, please contact customer service.',
  },
  authManagement: {
    authorizedPersonnel: 'Authorized Personnel',
    manageacl: 'Authorization Management',
    authTips: 'You may grant the data administrator permission to other existing super administrators by adding them here.',
    authManPhone: 'Extra Data Admin – Mobile',
    authName: ' Extra Data Admin – Name',
    remark: 'Remark',
    confirmDelete: 'Confirm to delete the record?',
    emptyTips: 'No relevant authorized personnel found.',
    deleteSuccess: 'The Authorized Personnel has been deleted successfully!',
  },
  authManagementCreate: {
    addAuthMenber: 'Assign Extra Data Admin',
    editAuthMenber: 'Edit Extra Data Admin',
    authPhone: 'Authorized mobile number',
    remark: 'Remark',
    authPhoneTips: 'If the added mobile phone number has not been registered, the property entity will automatically associate with the mobile phone after it is registered.',
    correctPhoneNum: 'Please input the correct cell phone number',
    phoneEmptyTips: 'Authorized mobile number is a mandatory field',
    nameWordsLimit: 'Name cannot exceed 25 words',
    createSuccess: 'The Authorized Personnel has been added successfully!',
    editSuccess: 'The Authorized Personnel has been edited successfully!',
  },
  authority: {
    manageacl: 'Access Groups',
    manageAclDescription: 'Permission control for reports and data',
    aclName: 'Access Group Name',
    description: 'Description',
    deleteAuthGroup: 'Please confirm whether to delete this permission group?',
    deleteSelectedBtn: 'Delete selected ({count})',
    emptyTips: 'No relevant access group found.',
    search: 'Search by Access Group Name & Description',
    deleteSuccess: 'The Access Group has been deleted successfully!',
  },
  authorityCreate: {
    addAuth: 'Add Access Groups',
    editAuth: 'Edit Access Groups',
    aclName: 'Access Group Name',
    description: 'Description',
    aclNameEmptyTips: 'Access Group Name is a mandatory field',
    accountNameOver: 'Name cannot exceed 20 words',
    data: 'Data',
    dataItemDescription: 'Restricting the data access within the same access group',
    setReportButton: 'Set Report',
    function: 'Function',
    createSuccess: 'The Access Groups has been added successfully!',
    editSuccess: 'The Access Groups has been edited successfully!',
  },
  authoritySetReport: {
    setReportAuth: 'Set Report Authority',
    enterSearch: 'Search by Content',
    rptName: 'Report Name',
    authorized: 'Authorized',
    setSuccess: 'set Report success!',
  },
  authorityMember: {
    groupMenber: 'Group Member',
    accountId: 'Username',
    accountName: 'Account Owner',
    accountRole: 'Role',
    search: 'Search by Username or Account Owner',
    deleteSuccess: 'The Group Member has been deleted successfully!',
    createSuccess: 'The Group Member has been added successfully!',
  },
  group: {
    adminGroup: 'Work Groups',
    adminGroupTips: 'Besides individual users, you can also assign an Inspection Plan or Work Order to a Work Group as a designated inspector.',
    groupName: 'Group Name',
    groupDescription: 'Description',
    memberNum: 'Number of Members',
    memberNumIs0: 'There are no members in this workgroup. Please add group members to this workgroup or reselect a workgroup',
    enterSearch: 'Search by Group Name or Description',
    createGroup: 'Create Group',
    editGroup: 'Edit Group',
    emptyTips: 'No relevant work group found.',
    deleteSuccess: 'The work group has been deleted successfully!',
    deleteGroup: 'Please confirm whether to delete this group?',
  },
  groupCreate: {
    groupName: 'Group Name',
    groupDescription: 'Description',
    groupNameEmptyTips: 'Group Name is a mandatory field ',
    groupNameOver: 'Name cannot exceed 50 words',
    createSuccess: 'The work group has been added successfully!',
    editSuccess: 'The work group has been edited successfully!',
  },
  groupMember: {
    groupMember: 'Group Member',
    accountId: 'Username',
    accountName: 'Account Owner',
    accountRole: 'Role',
    search: 'Search by Username or Account Owner',
    deleteSuccess: 'The Group Member has been deleted successfully!',
    createSuccess: 'The Group Member has been added successfully!',
    selectMemberEmpty: 'Select at least one account',
  },
  groupMemberCreate: {
    addGroupMenber: 'Add Member',
    search: 'Account or name',
  },
  location: {
    mainPatPoint: 'Inspection Points',
    mainPatPointTips:
      'Inspection Points are the definition of physical locations for all 313FM tasks associated with. Typically, the Inspection Points are assigned to key locations for staff to sign into or key asset to check, e.g. Main gate, back door, washroom, transformer room, MCP etc. Once the Inspection Points are defined, you can create the Patrol Routes or Work Order with it.',
    patPoint: 'Inspection Point',
    patPointName: 'Inspection Point Name',
    patPointNum: 'Inspection Point No.',
    patPointType: 'Label Type',
    needUseLabel: 'Scan to open',
    isEffective: 'Enabled',
    noLocal: 'No Inspection Points to Delete',
    allLocDeleteTips: 'Are you sure you want to delete all inspection points?',
    importPointer: `Successfully Imported {count} Records`,
    pleaseSelectExcel: 'Please select an Excel file',
    selectDoc: 'Select File',
    selectFileTips: 'Please select file',
    pleaseSelectExcelUpload: 'Please upload the Excel template file:',
    confirmDelete: 'Confirm to delete the record?',
    enterSearch: 'Search by Inspection Point Name / No.',
    selectPatPoint: 'Select Inspection Point',
    clearAll: 'Clear all',
    selectPointTit: 'Inspection points in use',
    selectedLength: 'selected {count}',
    importPatPointExecl: 'Import Inspection Point',
    importExcelInfo: 'Please prepare the data according to the import template. It may take long time to import if the file size is large, please wait.',
    downloadTemplate: 'Download Template',
    createPatPoint: 'Create Inspection Point',
    editPatPoint: 'Edit Inspection Point',
    emptyTips: 'No relevant inspection point found.',
    deleteSuccess: 'The Inspection Points has been deleted successfully!',
    bluetooth: 'Bluetooth',
    qrcode: 'QR Code',
    nfc: 'NFC',
    meters: ' Meters',
    detail: 'Detail',
    initMapFailed: 'Map initialization failed!',
    initGoogleMapFailed: 'Google Maps failed to loading, please try Baidu Maps in Account Detail.',
  },
  locationCreate: {
    patPointNameTips: 'Name of the Inspection Point, use to define the physical location or asset to inspect',
    pointNameEmptyTips: 'Inspection Point Name is a mandatory field',
    locLimit100Words: 'Inspection point name cannot exceed 100 characters',
    locNumLimit20Words: 'Inspection point number cannot exceed 20 characters',
    patPointNumTips: 'Numbering of Inspection Point',
    patPointTypeTips: 'Types of Inspection Points, it can be NFC, QR code, Bluetooth or GPS',
    isUseLabel: 'Must Scan Tag/QRCode on Arrival',
    isUseLabelTips: 'When enabled, require the inspector to scan the Tag/QRCode upon arrival to indicate physical presence',
    isEffectiveTips: 'Once unchecked, this Inspection Point will be excluded in the new generated Inspection Orders and Work Orders',
    lng: 'Lng',
    lat: 'Lat',
    enterSearch: 'Search by Content',
    mapPoint: 'Inspection Point GPS',
    patPointName: 'Inspection Point Name',
    patPointNum: 'Inspection Point No.',
    patPointType: 'Label Type',
    errorRange: 'Tolerance',
    isEffective: 'Enabled',
    createSuccess: 'The Inspection Point has been added successfully!',
    editSuccess: 'The Inspection Point has been edited successfully!',
  },
  holiday: {
    holidayManage: 'Holiday Management',
    holidayTips: 'No Daily/Hourly inspection order would be generated during holidays, except Weekly/Monthly/Yearly inspection orders.',
    holidayStart: 'Start Date',
    holidayEnd: 'End Date',
    holidayRemark: 'Holiday Title',
    importPointer: `Successfully Imported {count} Records`,
    pleaseSelectExcel: 'Please select an Excel file',
    selectDoc: 'Select File',
    selectFileTips: 'Please select file',
    pleaseSelectExcelUpload: 'Please upload the Excel template file:',
    copyHoliday: 'Clone to other Property Entity / Entities',
    noperMission: 'Sorry you have insufficient privilege',
    pleaseCheckHoliday: 'Please select the holiday to be copied',
    deleteSuccess: 'The holiday has been deleted successfully!',
    enterSearch: 'Search by Holiday Title',
    importHoliday: 'Import Holidays',
    emptyTips: 'No relevant holiday found.',
  },
  holidayCreate: {
    createTitle: 'Add Holiday',
    editTitle: 'Edit Holiday',
    holidayStartTips:
      'During the Start/End time range, the Inspection Order for the Daily and Hourly will not be generated. And the system will Not limit the overlapped holiday creation. For example, you may create 2 holidays as:  Jan 1 - 10th, and Jan 5 - 15th, so the final non-working time will be Jan 1 - 15th',
    holidayStart: 'Start Date:',
    holidayEnd: 'End Date:',
    noWorkdayRemark: 'Title:',
    createSuccess: 'The hoilday has been added successfully!',
    editSuccess: 'The hoilday has been edited successfully!',
    holidayLimitWords: 'Holiday notes cannot exceed 25 words',
    requiredStartdate: 'Please select the start date',
    requiredEnddate: 'Please select the end date',
  },
  holidayCopy: {
    pleaseCheckPenum: 'Please select the Property Entity to be copied',
    copySuccess: 'The holiday has been copied successfully!',
    propertyEntity: 'Property Entity',
    enterSearch: 'Search by Property Entity Name',
    selected: 'Selected {count}',
  },
  assets: {
    assetAsccount: 'Asset Ledger',
    assetLedgeRexPlain: 'Asset may be an inspection target, can be linked with a Location & work order.',
    enterSearch: 'Search by Asset Name / ID',
    assetsName: 'Asset Name',
    assetsId: 'Asset ID',
    location: 'Location',
    status: 'Status',
    assetDelTips: `This asset has been used in {num} work order templates such as "{text}". If deleted, the generated work order template will display abnormally. Please confirm whether to delete it? `,
    confirmDelete: 'Confirm to delete the record?',
    deleteSuccess: 'The asset has been deleted successfully!',
    assetStatusAll: 'All',
    assetStatusSelect: 'Please select status',
    switchAsset: 'Select asset',
    emptyTips: 'No relevant asset found.',
  },
  assetsCreate: {
    createTitle: 'Create Asset',
    editTitle: 'Edit Asset',
    viewTitle: 'Asset Ledger Details',
    assetsName: 'Asset Name',
    assetBrand: 'Brand',
    assetModel: 'Model',
    assetManufactureDate: 'Manufacturing Date',
    assetInstallDate: 'Installation Date',
    assetSerialNo: 'Serial Number',
    assetStatutory: 'Statutory Asset',
    assetStatutoryTips: 'Statutory Asset is an asset subject to government regulation and requirement',
    location: 'Location',
    assetDesc: 'Details',
    pleaseSelectDate: 'Select Date',
    assetPicTypeTips: 'Only .jpg, .jpeg, .png format files can be uploaded',
    assetAlert: 'Asset name is a mandatory field',
    assetLocationTips: 'Location is a mandatory field',
    assetAlertDesLength: 'Details no more than 1000 words',
    createSuccess: 'The asset has been created successfully!',
    editSuccess: 'The asset has been edited successfully!',
    status: 'Status',
  },
  assetsLocationSelect: {
    selectLocation: 'Select Inspection Point',
    enterSearch: 'Point number or name',
  },
  sensor: {
    sensor: 'IoT Sensors',
    sensorName: 'IoT Sensor name',
    sensorDesc: 'Description',
    sensorNum: 'IoT Sensor number',
    sensorCode: 'IoT Sensor code',
    sensorDelTips: `This sensor has been used in {count} monitor templates such as "{text}". If deleted, the generated monitor template will display abnormally. Please confirm whether to delete it? `,
    confirmDelete: 'Confirm to delete the record?',
    sensorExplain:
      'After setting up the IoT sensor, it can be associated with the 313 platform by creating the sensor here using the sensor code. IoT data can then be linked up with this sensor definition. Specific meters inside the sensor can be defined here, covering units, reading types, low limits, and high limits.',
    enterSearch: 'Search by Sensor Name, Code or Description',
    deleteSuccess: 'The sensor has been deleted successfully!',
    emptyTips: 'No relevant IoT sensor found.',
  },
  sensorCreate: {
    createTitle: 'Add IoT Sensor',
    editTitle: 'Edit IoT Sensor',
    viewTitle: 'IoT Sensor Details',
    sensorName: 'IoT Sensor name',
    sensorCode: 'IoT Sensor code',
    sensorDesc: 'Description',
    sensorPic: 'Photo',
    sensorNameEmptyTips: 'IoT Sensor name is a mandatory field',
    sensorCodeEmptyTips: 'IoT Sensor code is a mandatory field',
    createSuccess: 'The serson has been created successfully!',
    editSuccess: 'The serson has been edited successfully!',
    meter: 'Meter',
    createMeterTitle: 'Add Meter',
    editMeterTitle: 'Edit Meter',
    meterType: 'Meter Type',
    meterUnit: 'Meter Unit',
    meterReading: 'Meter Reading Type',
    sensorkey: 'IoT Sensor Encryption Key',
    sensorLowerLimit: 'Lower Limit',
    sensorLowerLimitTips: 'Lower Limit is a mandatory field',
    sensorUpperLimit: 'Upper Limit',
    sensorUpperLimitTips: 'Upper Limit is a mandatory field',
    numberTips: 'Please enter a number with a maximum of two decimal places',
    meterLowerTips: 'The meter configuration on the home page shows the minimum range',
    meterUpperTips: 'The meter configuration on the home page shows the maximum range',
    meterSelect: 'Please select',
    meterPleaseSelect: 'Please select Meter',
    meterTypeEmptyTips: 'Meter Type is a mandatory field',
    meterUnitEmptyTips: 'Meter Unit is a mandatory field',
    meterReadingEmptyTips: 'Meter Reading Type is a mandatory field',
    limitLessThanTips: 'The lower limit can only be less than or equal to the upper limit',
    limitGreatEroreQualHanTips: 'The upper limit can only be greater than or equal to the lower limit',
    meterAddTips: 'Please add meter',
  },
  userinfo: {
    accountDetailTitle: 'Account Detail',
    change: 'Change',
    configPhone: 'Phone No.',
    account: 'Account',
    configUserName: 'Name',
    email: 'Third-Party Account',
    timeZone: 'Time Zone',
    onlyKeep: 'Only keep the Inspection list attachment of last:',
    daystoinsattachment: 'days.',
    mapSelect: 'Select Map',
    allowTask: 'Allow APP to accept orders across Sites',
    dontShowContactBar: 'Hide the bottom contact bar',
    dontShowConsultation: 'Hide online consultation',
    phoneMsgTips: 'SMS or Voice Notification',
    password: 'Password',
    smsCountContent: 'SMS Usage: {num} / {total}',
    voiceCountContent: 'Voice Pack Usage: {num} / {total}',
    userLogo: 'Upload custom LOGO',
    qrLogo: 'Upload QR code LOGO',
    userLogoInfo: 'Click to upload the user-defined logo of the account. The recommended size is 280px * 200px, and the maximum size is 2MB',
    qrLogoInfo: 'Click to upload the user-defined QR code logo. The recommended size is 200px * 200px, and the maximum size is 2MB',
    editSuccess: 'Successfully Updated',
  },
  userinfoSsoConfig: {
    microsoftConfiguration: 'OAuth2 Configuration',
    tenantId: 'Tenant_Id',
    clientId: 'Client_Id',
    secret1: 'Key',
    secret1Date: 'Key Expiration Date',
    description: 'Description',
    saveSuccess: 'Saved Successfully',
  },
  userinfoEditPhone: {
    editPhoneTitle: 'Change Phone No.',
    configPhone: 'Phone No.',
    moreAreaCode: 'More Areacode',
    oldPassword: 'Old Password',
    newPass: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    confirmNewPasswordPh: 'Repeat New Password',
    verCode: 'Image Code',
    phoneCode: 'SMS Code',
    correctPhoneNum: 'Please input the correct cell phone number',
    configNewPassFormat: 'Please enter 6-20 digits, letters, or special symbols. Special symbols only @#&*_',
    repassTips: 'Input Same Password Again',
    correctImageCode: 'Verification code is a mandatory field',
    correctCode: 'Incorrect verification code, please input again, or resend.',
    getcodeBtn: 'Get Code',
    secgetcodeBtn: '{second} Seconds',
    phoneSendSuccess: 'Verification code sent successfully',
    editPhoneSuccess: 'The phone has been edited successfully!',
  },
  userinfoEditPass: {
    editPassTitle: 'Change Password',
    configNewPass: 'New Password',
    checkPassWord: 'Confirm Password',
    editPassSuccess: 'The password has been edited successfully!',
  },
  userinfoViewSms: {
    smsInfoDetailTitle: 'SMS List',
    enterSearch: 'Search by Content',
    detailTitle: 'Detail',
    smsPeDesc: 'Property Entity',
    smsContent: 'Content',
    smsSendTime: 'Sent Time',
    smsIsVoiceLabel: 'Voice Reminder',
    status: 'Status',
    success: 'Success',
    failure: 'Failure',
    startDate: 'Start Date',
    endDate: 'End Date',
    to: 'To',
    all: 'All',
    smsErrorMsgLabel: 'Error Message',
    smsSendName: 'Recipient',
    smsSendPhone: 'Phone Number',
    emptyTips: 'No relevant content found.',
  },
  notice: {
    notice: 'Notification',
    noticeTips: 'Notification messages can be sent to the relevant personnel of the property entity via the 313fm mobile app & web site.',
    noticeTitle: 'Title',
    noticeOutline: 'Summary',
    publishName: 'Publisher',
    publishTime: 'Publish Time',
    deleteSuccess: 'The notice has been deleted successfully!',
    noticeQueryTips: 'Search by Title',
    emptyTips: 'No relevant notification found.',
  },
  noticeRead: {
    noticeReadTitle: 'Notification Details',
    notifyperName: 'Notified Person',
    isRead: 'Read',
    noticeReadTime: 'Read Time',
  },
  noticePublish: {
    publishNotice: 'Notification',
    noticeTips: 'Message broadcast can be issued to relevant personnel in the property entity, and the notifications will be posted to the mobile app.',
    noticeTitlePlaceholder: 'Please enter title',
    noticeTitleTips: 'Title is a mandatory field',
    noticeContentPlaceholder: 'Please enter content',
    noticeContentTips: 'Content is a mandatory field',
    noticeTitleOver: 'Title cannot exceed 50 words',
    noticeContentOver: 'Content cannot exceed 300 words',
    setNoticeRange: 'Set Notification Range',
    notifyAll: 'Notify Everyone',
    checkSelNoticeper: 'Please select the notified people!',
    publishSuccess: 'The new notification has been published successfully!',
  },
  noticePublishRange: {
    dialogNotifyAllTitle: 'Person to Notify',
    UserList: 'User List',
    clearAll: 'Clear all',
    selected: 'Selected {count}',
  },
  selectTaskTemplate: {
    enterRsearch: 'Search by Content',
    instask: 'Inspection Plan',
    executor: 'Assignee',
  },
  task: {
    mainInstask: 'Inspection Plan Management',
    mainInstaskTip: 'Define the content of the inspection tasks, including which inspection points to be inspected, what inspection items at each inspection point, the implementation plan of inspection plan, and by whom to complete the inspection plan, etc.',
    name: 'Task',
    inspPlan: 'Inspection Plan',
    toCreateTempTask: 'Each {interval} {frequnit} To Generate Inspection Order',
    tempTask: 'Ad-hoc Patrol Order',
    onTimeCompletion: '{count} Completed (On Time)',
    overdueCompletion: '{count} Completed (Overdue)',
    cancelled: '{count} Cancelled',
    notFollowUp: '{count} Pending',
    checkStartTask: 'The next scheduled inspection time or the inspector is not set, and the inspection plan cannot be started.',
    startTask: 'The inspection plan has been started successfully!',
    stopTask: 'The inspection plan has been stopped successfully!',
    nextInsTime: 'Next Inspection Time',
    assignee: 'Assignee',
    insman: 'Assignee',
    reviewer: 'Reviewer',
    insTaskQueryTips: 'Search by Plan Name or Task Number',
    deleteSuccess: 'The Inspection Plan has been deleted successfully!',
    emptyTips: 'No relevant inspection plan found.',
  },
  taskCreate: {
    createPatTask: 'Create Inspection Plan',
    editPatTask: 'Edit Inspection plan',
    taskName: 'Plan Name',
    pleaseInputRctaskName: 'The plan name is a mandatory field',
    checkSelinsExist: 'The inspector is a mandatory field',
    pleaseRcwoDate: 'The inspection date time is a mandatory field',
    pleaseInterval: 'The inspection plan interval is a mandatory field',
    nextScheduledTimeTips: 'The next scheduled time is a mandatory field',
    correctIntervalTips: 'Please enter the correct time interval',
    patPoinAndPatitem: 'Inspection Point and Inspection Item',
    patPlan: 'Inspection Plan',
    addTaskNameTips: 'Inspection Plan name for tasks repeatedly executed within a period, below are some examples of the Inspection Plan name:Security patrol route A,Fire Extinguisher Monthly Maintenance,Elevator Annual Checkup, etc.',
    setExecutor: 'Inspector',
    selectExecutor: 'Member',
    selectGroup: 'Group',
    perint: 'Frequency: Each',
    togenPatList: 'Generates an Inspection Order',
    intervalTips: '(The system will generate Inspection Order in No. 1, {nexttime} {frequnit})',
    nextInsPlanTime: 'Next Scheduled Time',
    reviseInsPlanTime: 'Revise Inspection Time',
    to: 'To',
    groupList: 'Group List',
    executorList: 'Inspector List',
    reviewerList: 'Reviewer List',
    selectReviewerTips: 'Please Select the Reviewer',
    effTimeRange: 'Effective Time Range',
    validPeriod: 'Planned Expiry Datetime',
    validPeriodLast: '',
    validPeriodTips: 'The inspection plan would be automatically disabled after the expiry datetime',
    weeklySet: 'Effective Weekday',
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday',
    other: 'Other',
    holiday: 'Holiday',
    monthSeting: 'Effective Month',
    Jan: 'January',
    Feb: 'February',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'August',
    Sept: 'September',
    Oct: 'October',
    Nov: 'November',
    Dec: 'December',
    holidayTips: 'If you do not need to generate an Inspection Order for other days or non-working days, you can click “Holiday Management” in the left menu to set it.',
    checkSelectExecutor: 'Please Select the Inspector',
    checkSelectGroup: 'Please Select the Group',
    efftimeRangeInfoOne:
      'It is used to define the time range of the patrol. For example, the equipment pump room of an enterprise must conduct an inspection every hour between 8 am and 5 pm, and the effective time range is 08:00 to 17:00. An Inspection Order will be generated within the effective time range, or it can be generated early.',
    efftimeRangeInfoTwo: "If the 'End Time' is earlier than the 'Start Time', the representative time range is the next day and the end of the time point.",
    efftimeRangeInfoThree: 'If not filled, it means 24 hours.',
    efftimeRangeInfoFour: `This setting is only valid when frequency is set to 'Hour(s)', you may leave blank for other frequencies.`,
    inspectionPlanOptions: 'Inspection Plan Options',
    autoCreateTask: 'Activate Unplanned Inspection Mode',
    isNeedAdvance: 'Generate Inspection Order In Advance',
    autoCreateTaskDescRiption: 'After checking, this inspection plan will no longer be generated based on the set inspection time and frequency. As long as the current inspection form is completed, the next inspection form will be automatically generated immediately.',
    isNeedAdvanceInfo: "For example, the scheduled execution time of the inspection plan is 10:00 am. You can set the inspection order to generate 1 hour in advance. At 9:00 am every day, the corresponding inspection order will be sent to the inspector's mobile app task box.",
    needAdvanceHourTocReateins: 'In advance {content} to generate Inspection Order',
    openCondition: 'Apply Activation Criteria ',
    openConditionTips:
      "You can control when to activate the Inspection Plan in the settings of the mobile app. Inspection orders that do not meet the criteria will be deleted and will stop generating. Inspection orders that fulfill the criteria will be generated automatically. Orders not marked as 'Apply Activation Criteria' will not be affected.",
    pause: 'Disable',
    openStart: 'Enable',
    multiPersonInspection: 'Allow Multiple People Collaboration',
    multiPersonInspectionTips: 'When checked, this inspection order can be executed by members of the group at different inspection points and cannot be forwarded',
    multiPersonInspectionCheckedTips: 'After checking the option to "Allow Multiple People Collaboration", "Must Follow Inspection Point Sequence" will become invalid',
    inspectionOptions: 'Inspection Options',
    autoCompUponScan: 'Scan To Complete / Patrol Stick Mode',
    autoCompUponScanInfo:
      "When checked, this inspection point will be automatically completed if no checklist is attached. This is convenient for situations where consecutive scanning is required. If not checked, users will have to click the 'Complete' button before proceeding to the next checkpoint.",
    needRoute: 'Must Follow Inspection Point Sequence',
    needRouteInfo: 'When checked, patrol inspection shall be completed in sequence according to the serial number of inspection points.',
    needPhoto: 'Must Upload Photo',
    needPhotoInfoOption: 'When this option is checked, a photo must be uploaded for all the check points under this patrol route',
    scanAgain: 'Scan Again Upon Inspection Point Task Completion',
    // scanAgain: 'Scan again upon completion',
    scanAgainInfo: 'When checked, you need to scan again the tag after completing the assigned tasks of the inspection point.',
    // scanAgainInfo: 'When checked, all the points on the  plan route will have to Scan again upon completion',
    noCancel: 'Not Allow To Cancel',
    noCancelInfo: 'When checked, this WO is not allowed to cancel',
    signinAdvance: 'Sign In Advance',
    signMethod: 'Signature Method',
    handlewrite: 'Handwritten Signature',
    signPic: 'Face Selfie',
    managementOptions: 'Management Options',
    isNeedReviewer: 'Review Required',
    setReviewerInfo: 'If set to Required, after the inspection order is completed, the order will be submitted to the designated property entity manager for review.',
    promanagerNotificationOptions: 'Reminder Options',

    addPatPoint: 'Add Inspection Point',
    addPatPointTips: 'The Inspection Plan is a task that needs to be performed regularly, and the place to be inspected and the contents (Inspection Item) of the inspection are added here',

    taskRemind: 'Remind When No Response',
    taskRemindTips: 'This is a circular reminder until the Inspection Order is started',
    needPhotoInfoPart: 'When this option is checked，a photo must be uploaded before this check point patrol can be finished',
    makeWarning: 'Inspection Order Overdue',
    makeWarningTips:
      'This is to remind the various people in following ways: \n1, System will create a record in the Alarm Call List\n2, Inform the Energency Contact of the Property Entity vis SMS\n3, Inform the Property Manager via Mobile App\n\nBear in mind this function only works if the network is avaialble',
    recordOnly: 'Record Only',
    mustBeMinutes: 'Must arrive within {content} minute(s)',
    earlyMinToConsider: 'Early By {earlyContent} Minute(s) or Late By {lateContent} Minute(s) are abnormal, then',
    seNum: 'ID',
    patContent: 'Inspection Content',
    feedBackVal: 'Feedback',
    needFeed: 'Mandatory?',
    colData: 'Data / Meter Reading',
    warningCheckNeedRoute: 'Check "Inspection Options" - "Must Follow Point Sequence" to take effect',
    taskRemindDesc: 'Inspector Must Start Within {content} Minute(s) of Planned Schedule, or else, remind Inspector.',
    justOneReminder: 'Remind Only Once',
    remindLimitTip: 'Up to 10 reminders',

    respondIsIntervalLabel: 'Reminder every {content} minute(s) until the expected completion time or response time is reached',
    remindRecipientLabel: 'Set Reminder Recipients',
    executor: 'Assignee',
    peManager: 'Property Manager',
    remindOther: 'Other',

    remindPushMethodLabel: 'Reminder Methods',
    remindPushApp: 'App Push',
    remindPushSms: 'SMS',
    remindPushVoice: 'VOICE',
    taskRemindRequired: 'The Remind When No Response is a mandatory field',
    reviewerRequired: 'The Inspection reviewer is a mandatory field',
    respondIntervalRequired: 'The time interval is a mandatory field',
    leadtimeRequired: 'The generate inspection time in advance is a mandatory field',
    outtimeTimelimitRequired: 'The complete time is a mandatory field',
    pointTimeoutTips: 'Inspection Point Arrival Delay',
    locDelayLabelTip: 'It is necessary to set the arrival time at the inspection point and check the option that must be inspected in order',
    firstPolling: 'Count On First Inspection Point',
    prevPolling: 'Count On Previous Inspection Point',
    remindIsAlertLabel: 'Recorded in alert records',
    noSupTips: 'The current version does not support this function!',
    isSaveWithoutLoc: 'Inspection point is not added to the current plan. Do you want to continue creating?',

    insneedMinuteToComp: 'Must Finish Within {content} Minute(s), Otherwise Send Timeout Remind',
    overTimeIsAbnormal: 'Over by {content} Minute(s) is considered as Abnormal',
    taskRemindToFinish: 'Sent Time',
    taskRemindToFinishTips: 'When enabled, a reminder will be sent to the property entity managers when the inspection order or work order is completed.',
    remindExecuteLabel: 'Inspection Checklist Execution Reminder',
    remindExecuteLabelTip: 'Forwarding, reviewing, and returning inspection orders will send reminders to the executor',
    trackingOptions: 'Tracking Options',
    calsignTime: 'Calculate Arrival Time',
    mileage: 'Patrol Mileage {content} Km',
    mileageTips: 'Patrol Mileage cannot be less than 0',
    mileageInfo: 'The total mileage of this Inspection Order is the sum of the distances of all inspection points',
    trarecfre: 'Track Trail Collection Frequency {content} Minute(s)',
    trarecfreTips: 'Track Trail Collection Frequency cannot be less than 0',
    cancelCheckNeedRouteTips: 'After unchecking Must Follow Inspection Point Sequence, Inspection Point Arrival Delay and Inspection Order Overdue will become invalid',
    addPatItem: 'Add Inspection Item',
    selectComItem: 'Select SOP',
    selectSysItem: 'Select System SOP',
    copyPatItem: 'Copy from other Inspection Point',
    patPointName: 'Inspection Point Name',

    taskExample: 'Preview Plan',
    taskExampleTipsTitle: 'Instruction:',
    taskExampleTipsHours: 'For Frequency in Hour/Day/Week: 2 months records will be listed',
    taskExampleTipsMonth: 'For Frequency in Month: 12 months records will be listed',
    taskExampleTipsYear: 'For Frequency in Year: 10 years records will be listed',

    taskUse: 'Save and Start',
    taskGenerate: 'Generate Inspection Order',

    remindMethodSmsCheckedTip1: 'You need to check "Account Detail - SMS or Voice Notification" to take effect',
    remindMethodSmsCheckedTip2: 'The remaining number of SMS messages is 0',
    remindMethodVoiceCheckedTip: 'The remaining number of voice messages is 0',
    locChange: 'Change',
    top: 'Top',
    bottom: 'Bottom',
    moveUp: 'Move Up',
    moveDown: 'Move Down',
    expand: 'Expand',
    collapse: 'Collapse',
  },
  taskDetail: {
    viewInstaskDetail: 'Inspection Plan Details',
    copyToNewTask: 'Clone to New Plan',
    cmpontime: 'Completed (On Time)',
    cmpovrdue: 'Completed (Overdue)',
    notfollow: 'Pending',
    cancelled: 'Cancelled',
    taskNum: 'Plan No.',
    taskName: 'Plan Name',
    perint: 'Frequency:',
    togenPatList: 'Generates an Inspection Order per {num} {unit}',
    intervalTips: '(The system will generate Inspection Order in No. 1, {nexttime} {frequnit})',
    nextInsplanTime: 'Next Scheduled Time',
    effTimeRange: 'Effective Time Range',
    notset: 'Not set',
    to: 'To',
    nextDay: 'The Next Day',
    insman: 'Assignee',
    notpoint: 'Unassigned',
    reviewer: 'Reviewer',
    historyTotal: 'History Total',
    trarecfre: 'Track Trail Collection Frequency',
    modinsExecutor: 'Modify Assignee',
    selectGroup: 'Group',
    selectExecutor: 'Member',
    weeklyEfftime: 'Effective Weekday',
    monthSeting: 'Effective Month',
    needAdvance: 'In advance ',
    toCompover: 'Otherwise will be counted as Completed (Overdue)',
    insNeed: 'Must Finish Within',
    mileage: 'Patrol Mileage',
    kilo: 'Km',
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday',
    other: 'Other',
    holiday: 'Holiday',
    Jan: 'January',
    Feb: 'February',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'August',
    Sept: 'September',
    Oct: 'October',
    Nov: 'November',
    Dec: 'December',
    effect: 'Active',
    recentFive: 'Last 5 Inspection Orders',
    patNum: 'Inspection Order',
    insplanTime: 'Planned Inspection Time',
    actComTime: 'Actual Completion Time',
    insmanName: 'Inspector',
    status: 'Status',
    foleventrep: 'Incident Report',
    currencyLoc: 'Unknown Point',
    deleteLocation: 'The inspection point has been deleted',
    homeLoc: 'Inspection Point',
    seNum: 'ID',
    patContent: 'Inspection Content',
    feedBackVal: 'Feedback',
    needFeed: 'Mandatory?',
    colData: 'Data / Meter Reading',
    checkStartTask: 'The next scheduled inspection time or the inspector is not set, and the inspection plan cannot be started.',
  },
  commonList: {
    commonListTitle: 'Standard Operating Procedure (SOP) Management',
    titleTips: 'Defined Standard Operating Procedure (SOP) to be used in inspection plan and work order template. SOP is also known as an inspection checklist.',
    patItemDesc: 'Inspection Item',
    category: 'Category',
    default: 'Default',
    patIntputType: 'Input Mode',
    needFeed: 'Mandatory?',
    coldData: 'Data / Meter Reading',
    refCount: 'Citation Count',
    deleteCommonSuccess: 'The inspection item has been deleted successfully!',
    deleteSystemSuccess: 'The inspection item has been deleted successfully!',
    commonListButton: 'SOP',
    systemListButton: 'System SOP',
    comItemManQueryTips: 'Search by Inspection Item',
    comCategoryQueryTips: 'Search by Category',
    newPatItem: 'Add New Inspection Item',
    editPatItem: 'Edit Inspection Item',
    emptyTips: 'No relevant SOP found.',
  },
  commonListCreateCommon: {
    patItemDesc: 'Inspection Item',
    categopy: 'Category',
    patInputType: 'Input Mode',
    feedBackVal: 'Feedback',
    addOne: 'Add One',
    setTem: 'Choose from Template',
    tmplEmptyTips: 'No relevant template found.',
    saveTem: 'Save as Template',
    inputLimit: 'Entry Limit',
    defVal: 'Default Value',
    defValInfo: 'It is the default feedback value of this inspection item on the mobile app.',
    isNeedFeedBack: 'Mandatory?',
    feedBackInfo: 'If mandatory, the inspector cannot leave this inspection item blank on the mobile APP.',
    isColData: 'Data / Meter Reading',
    colDataInfo: 'If so, the corresponding readings and charts can be retrieved in the Data / Meter Reading Module',
    inputInsItemContentTips: 'Inspection Item is a mandatory field',
    onehundredandfifty: 'The Inspection Item should not exceed 150 characters',
    pleaseSelectFeedBack: 'Please Select Feedback',
    checkSelectValLen: 'Can not exceed 9 options!',
    createSuccess: 'The new inspection item has been created successfully!',
    editSuccess: 'The inspection item has been edited successfully!',
    addPatItem: 'Add Inspection Item',

    isUploadPic: 'Allow To Upload Pictures',
    picClassify: 'Picture Classification',
    defValNeedFromFeedback: 'Default value needs to come from feedback options',
    setCom: 'Set as SOP',
    setComSuccess: 'Successfully set as SOP!',
  },
  woTemplate: {
    mainWoTemplate: 'Work Order Template Management',
    woTemplateListTips: 'Work order template specifies the inspection points to be checked during work order execution, along with detailed inspection item information and the responsible inspector. Note that unlike routine inspection orders, work orders are generated for ad-hoc execution.',
    pleaseInputWoTemName: 'Search by Template Name / Number',
    woCatSelectTips: 'Select Classification',
    template: 'Template',
    name: 'Template Name',
    suswo: 'Continuous Work Order',
    onTimeCompletion: '{count} Completed (On Time)',
    overdueCompletion: '{count} Completed (Overdue)',
    cancelled: '{count} Cancelled',
    notFollowUp: '{count} Pending',
    assignee: 'Assignee',
    insman: 'Assignee',
    reviewer: 'Reviewer',
    categopy: 'Category',
    createNewWoTem: 'Create Work Order Template',
    createWo: 'Create Work Order',
    deleteSuccess: 'The Work Order Template has been deleted successfully!',
    emptyTips: 'No relevant work order template found.',
  },
  woTemplateCreate: {
    tabName: 'Work Order Template',
    woTemplateName: 'Template Name',
    woTemplateNameEmptyTips: 'The template name is a mandatory field',
    workOrderTemplateNum: 'Work order template number',
    editWoTemplate: 'Edit work order template',
    woTemplateCat: 'Template Classification',
    woCatDesc: 'Classification Description',
    woCatSelectTips: 'Please Select Classification',
    woRule: 'Work Order Setting',
    deleteLocation: 'The inspection point has been deleted',
    deleteAsset: 'The asset has been deleted',
    woIsEditLoc: 'Allow To Modify Inspection Point',
    woIsDelLoc: 'Allow To Delete Inspection Point',
    wosustainabilily: 'Continuous Work Order?',
    wosustainabililyTips: 'If it is a continuous order, the same Work Order will be generated again when the preivous one completed.',
    woNeedReviewer: 'Need Reviewer',
    woReviewerInfo: 'When checked, after the work order is completed, the order will be submitted to the designated property manager for review.',
    makeWarning: 'Work Order Overdue Notification',
    needPhoto: 'Must Upload Photo',
    needPhotoInfo: 'When this option is checked, a photo must be uploaded for all the check points under this patrol route',
    remindIsAlertLabel: 'Recorded in alert records',
    scanAgain: 'Scan Again Upon Inspection Point Task Completion',
    scanAgainInfo: 'When checked, you need to scan again the tag after completing the assigned tasks of the inspection point.',
    noCancel: 'Not Allow To Cancel',
    noCancelInfo: 'When checked, this WO is not allowed to cancel',
    taskRemindToFinish: 'Sent Time',
    taskRemindToFinishTips: 'When enabled, a reminder will be sent to the property entity managers when the inspection order or work order is completed.',
    advanceSetting: 'Advanced Settings',
    ifSendWork: 'Can Create Order For Others',
    autoCompuponScan: 'Scan To Complete / Patrol Stick Mode',
    autoCompuponScanInfo:
      "When checked, this inspection point will be automatically completed if no checklist is attached. This is convenient for situations where consecutive scanning is required. If not checked, users will have to click the 'Complete' button before proceeding to the next checkpoint.",
    needRoute: 'Must Follow Inspection Point Sequence',
    needRouteInfo: 'When checked, patrol inspection shall be completed in sequence according to the serial number of inspection points.',
    calSignTime: 'Calculate Arrival Time',
    createWoScanLocNecessary: 'Compulsory Inspection Point Scanning Is Required When Creating Inspection Points',
    createWoScanLocNecessaryDescription: `When creating a work order on Mobile App using this template, you must scan the QR code/NFC/Bluetooth/GPS of the inspection point correctly in order to replace the inspection point.`,
    addAsset: 'Add Asset',
    currencyLoc: 'Unknown Point',
    assetsName: 'Asset Name',
    homeLoc: 'Inspection Point',
    woTempClassNull: 'Template classification is a mandatory field',
    createSuccess: 'The new work order template has been created successfully!',
    editSuccess: 'The work order template has been edited successfully!',
    slaMenuTitle: 'SLA Configuration',
    slaDescLabel: 'Priority',
    slaFbtLabel: 'Target Response Time (Minutes)',
    slaTitle: 'SLA (Service Level Agreement) Setting',
    slaSearchPlhld: 'Search by Priority',
  },
  woTemplateDetail: {
    templateName: 'Template Name',
    viewWoTemplate: 'View Template',
    woTemplateNum: 'Work Order Template No.',
    woTemplateName: 'Work Order Template Name',
    woTemplateCat: 'Work Order Template Classification',
    slaFbtLabel: 'Target Response Time (Minutes)',
    wosustainabilily: 'Continuous Work Order?',
    woNeed: 'The work order should be completed within',
    slaDescLongLabel: 'Priority of SLA configuration',
    overBy: 'Over by',
    woAbnormal: 'Minutes is considered as abnormal',
    needPhoto: 'Must Upload Photo',
    currencyLoc: 'Unknown Point',
    deleteAsset: 'The asset has been deleted',
    deleteLocation: 'The inspection point has been deleted',
    meterNum: 'Instrument No.',
    woFive: 'Latest 5 Related Work Orders',
    woNum: 'Work Order No.',
    insplanTime: 'Plan Inspection Time',
    actComTime: 'Actual Completion Time',
    actExecutor: 'Inspector',
    status: 'Status',
    foleventrep: 'Incident Report',
    woTemplateCopy: 'Clone as New Template',
    insmanName: 'Inspector Name',
  },
  woList: {
    woList: 'Work Orders',
    wo: 'Work Order',
    recNum: 'No.',
    desc: 'Description',
    details: 'Detail',
    category: 'Category',
    insplanTime: 'Plan Inspection Time',
    actStartTime: 'Actual Start Time',
    actComTime: 'Actual Completion Time',
    overTime: 'Time Out',
    executor: 'Assignee',
    unspec: 'Unspecified',
    homeReviewed: 'reviewed',
    results: 'Results',
    incReport: '{count} Incident Report',
    allInslist: 'All Work Orders',
    nearlyaweekinslist: 'Orders (Last 7 Days)',
    nearlyonemonthinslist: 'Orders (Last 30 Days)',
    nearlyhreemonthsinslist: 'Orders (Last 90 Days)',
    normal: 'Normal',
    abnormal: 'Abnormal',
    comOnTime: 'Completed (On Time)',
    waitingStart: 'WaitingStart',
    inProgress: 'InProgress',
    pending: 'OnHold',
    peoCancelled: 'Cancelled',
    unProcess: 'Pending',
    omitLoc: 'OmitLoc',
    notTimeRange: 'NotPlannedTime',
    createWo: 'Create Work Order',
    createNewWoTem: 'Create Work Order Template',
    selectStartDate: 'Select Start Date',
    selectEndDate: 'Select End Date',
    woCatSelectTips: 'Select Classification',
    woSearch: 'Search by Work Order No. / Description / Inspector',
    viewMapTrack: 'View Track Trail',
    viewWoTemplate: 'View Template',
    viewDetail: 'View Detail',
    emptyTips: 'No relevant work order found.',
  },
  woListDetail: {
    viewWoDetail: 'View Work Order',
    print: 'Work Order',
    printAbnormal: 'Work Order (Abnormals Only)',
    details: 'Detail',
    viewPicDetail: 'View Pictures',
    pendingReason: 'Reason for ONHOLD',
    eventPic: 'Work Order Photo',
    picFailed: 'Failed',
    noPics: 'No Pictures',
    noPicCatData: 'No Data',
    viewMatdet: 'View Items',
    materialNum: 'Material No.',
    matdetDesc: 'Description',
    invtaddqty: 'Quantity',
    materialwh: 'Warehouse',
    invtshelf: 'Shelf',
    smsType: 'Type',

    matIssue: 'Issue Item',
    matReturn: 'Return Item',
    matAvgcstadj: 'Average Cost Adjustment',
    matCurbaladj: 'Current Balance Adjustment',
    matInsertItem: 'New Item Inserted',
    matReceipt: 'Receive Item',
    monitorList: 'IoT Readings',
    sensorCode: 'IoT Sensor code',
    sensorName: 'IoT Sensor name',
    meterName: 'Meter Name',
    monitorIndicesNum: 'Measured value',
    monitorIndicesDifference: 'Difference value',
    monitorIndicesDate: 'Time',
    cancelWord: 'Cancel Work Order',
    reason: 'Reason',
    enterCancelWord: 'Please Enter The Reason For Canceling',
    enterCancelWordTips: 'Reason For Canceling is a mandatory field',
    successCancelWord: 'The order has been cancelled',
    editWoTips: ' If modify work order that is in progress under offline mode, current work order inspection data will be lost. Please confirm to modify?To avoid data lost, please have the inspector to modify the configuration in Mobile App work order execution page.',
  },
  woCreate: {
    createWo: 'Create Work Order',
    editWork: 'Edit Work Order',
    createWoTips:
      'This is the list of work order templates, including which inspection points must be checked when the work order is carried out, the inspection item details, and who completes it. The difference between Inspection Order and Work Order is, the Work Order is not a routine task, it is created for the ad-hoc execution.',
    woOrderInfomation: 'Work Order Details',
    woName: 'Work Order Name',
    woCat: 'Work Order Classification',
    woNameEmptyTips: 'The work order name is a mandatory field',
    woCatEmptyTips: 'Work order classification is a mandatory field',
    woCatSelectTips: 'Please Select Classification',
    woCatDesc: 'Classification Description',
    nextWoPlanTime: 'Planned Execution Time',
    woRule: 'Work Order Setting',
    woSustainability: 'Continuous Work Order?',
    woSustainabilityTips: 'If it is a continuous order, the same Work Order will be generated again when the preivous one completed.',
    woNeedReviewer: 'Need Reviewer',
    reviewerEmptyTips: 'The reviewer is a mandatory field',
    woReviewerInfo: 'When checked, after the work order is completed, the order will be submitted to the designated property manager for review.',
    makeWarning: 'Work Order Overdue Notification',
    needPhoto: 'Must Upload Photo',
    needPhotoInfo: 'When this option is checked, a photo must be uploaded for all the check points under this patrol route',
    scanAgain: 'Scan Again Upon Inspection Point Task Completion',
    scanAgainInfo: 'When checked, you need to scan again the tag after completing the assigned tasks of the inspection point.',
    noCancel: 'Not Allow To Cancel',
    noCancelInfo: 'When checked, this WO is not allowed to cancel',
    taskRemindToFinish: 'Sent Time',
    taskRemindToFinishTips: 'When enabled, a reminder will be sent to the property entity managers when the inspection order or work order is completed.',
    ifSendWork: 'Can Create Order For Others',
    autoCompuponScan: 'Scan To Complete / Patrol Stick Mode',
    autoCompuponScanInfo:
      "When checked, this inspection point will be automatically completed if no checklist is attached. This is convenient for situations where consecutive scanning is required. If not checked, users will have to click the 'Complete' button before proceeding to the next checkpoint.",
    needRoute: 'Must Follow Inspection Point Sequence',
    needRouteInfo: 'When checked, patrol inspection shall be completed in sequence according to the serial number of inspection points.',
    calSignTime: 'Calculate Arrival Time',
    selectPic: 'Work Order Photo',
    picTips: 'According to the version you purchased, you can upload up to {count} photos per work order,and the picture size should be within 1M.',
    details: 'Detail',
    slaFbtLabel: 'Target Response Time (Minutes)',
    slaDescLongLabel: 'Priority of SLA configuration',
    implementationDateRequired: 'The planned execution time is a mandatory field',
    isNeedToChangeLocTips: 'The work order location has not been specified. Do you need to specify it?',
    duse: 'Kick-off',
    createSuccess: 'The new work order has been created successfully!',
    editSuccess: 'The work order has been edited successfully!',
  },
  punchInTemplate: {
    mainSignInTemplate: 'Punch-in Template Management',
    pleaseInputSignInTemplate: 'Search by Punch-in Template Name',
    signInTemplateCategory: 'Template Category',
    tmplName: 'Template Name',
    setSignInTemplateInsexecutor: 'Inspector',
    punchInMethod: 'Punch-in Method',
    deleteSuccess: 'The punch-in template has been deleted successfully!',
    emptyTips: 'No relevant punch-in template found.',
    valid: 'Valid',
    invalid: 'Invalid'
  },
  punchInTemplateCreate: {
    createSignInTemplate: 'Create Punch-in Template',
    editSignInTemplate: 'Edit Punch-in Template',
    signInTemplateName: 'Punch-in Template Name',
    signInTemplateCategory: 'Template Category',
    setSignInTemplateInsexecutor: 'Inspector',
    clockInfrequency: 'Minimal Punch-in Time Interval',
    clockInfrequencyTips1: 'The Minimal Punch-in Time Interval is set to the minimum time required for punching in at the same inspection point again. This helps prevent accidental punch-ins or incorrect reports.',
    clockInfrequencyTips2: 'e.g. when the interval is set to 10 seconds, a user can only perform a second punch-in 10 seconds after the previous one.',
    punchInMethod: 'Punch-in Method',
    signInDesRequired: 'Punch-in Template Name is a mandatory field',
    assignGroupRequired: 'Inspector is a mandatory field',
    minimumInterval: 'Minimal Punch-in Time Interval is a mandatory field',
    pointArrivalTit: 'Daily Punch-in Plan Management',
    addPatPoint: 'Select Inspection Point',
    time: 'Expected Time',
    desc: 'Description',
    alertWarning: 'Alerts/Warnings',
    addTimeManagement: 'Add Punch-in Time',
    createSuccess: 'The new punch-in template has been created successfully!',
    editSuccess: 'The punch-in template has been edited successfully!',
  },
  punchInTemplateAddInstrument: {
    addTimeManagement: 'Add punch-in time management',
    editTimeMamagement: 'Edit punch-in time management',
    time: 'Time',
    desc: 'Description',
    alertWarning: 'Alerts/Warnings',
    requiredTime: 'Please select a time',
    requiredDescription: 'Please select a description',
    requiredAlart: 'Please select a warning',
    requiredTimeManagement: 'Please add sign in time management',
  },
  monitorTemplate: {
    mainMonitoringTemplate: 'IoT Monitoring Setup',
    templateName: 'Monitoring Rule',
    monitorMainTemplateNanceexplain: 'Managing the monitoring rule of sensors and updating the threshold limit for triggering corresponding work order generation.',
    monitorQueryTips: 'Search by Monitoring Rule or IoT Sensor code',
    sensorCode: 'IoT Sensor code',
    deleteSuccess: 'The IoT Monitoring has been deleted successfully!',
    disabled: 'Disabled',
    enabled: 'Enabled',
    disable: 'Disable',
    enable: 'Enable',
    emptyTips: 'No relevant IoT monitoring found.',
  },
  monitorTemplateCreate: {
    createMonitoringTemplate: 'Create Monitoring Rule',
    editmonitor: 'Edit Monitoring Rule',
    monitoringName: 'Monitoring Rule Name',
    meterItem: 'Sensors',
    selectMeter: 'Select Sensor',
    sensor: 'IoT Sensors',
    sensorName: 'IoT Sensor name',
    sensorCode: 'IoT Sensor code',
    sensorDesc: 'Description',

    operationUpperLimit: 'As the upper limit of operation',
    operationLowerLimit: 'As the lower limit of operation',
    operationEqualLimit: 'As operation equals',
    operationIncrement: 'As operation increment',
    addMeterIndices: 'Add meter',

    meter: 'Meter',
    alertValue: 'Warning value',
    workOrderTemplate: 'Work Order Template',
    pleaseInputMonitoringName: 'Monitoring Rule Name is a mandatory field',
    monitoringNameExceedTips: 'Monitoring name cannot exceed 50 characters',

    createSuccess: 'The new monitor template has been created successfully!',
    editSuccess: 'The monitor template has been edited successfully!',
  },
  monitorTemplateAddMeter: {
    meter: 'Meter',
    alertValue: 'Warning value',
    operationUpperLimit: 'As the upper limit of operation',
    operationLowerLimit: 'As the lower limit of operation',
    operationEqualLimit: 'As operation equals',
    startDate: 'Start Date',
    endDate: 'End Date',
    workOrderTemplate: 'Work Order Template',
    isAllowCreateNew: 'Allows to create a new work order before the last work order is completed',
    requiredMeter: 'Please select meter',
    requiredWarnPoint: 'Warning value  is a mandatory field',
    requiredOperation: 'Action is a mandatory field',
    requiredCharacteristic: 'Feature meter can only be input in English',
    requiredNotCharacteristic: 'Only numbers can be entered',
    selectWorkOrderTemplate: 'Select work order template',
    addMeter: 'Add Meter',
    editMeter: 'Edit Meter',
    remindMethod: 'Alert backend in following ways',
    voice: 'Voice Call',
    sms: 'SMS',
    mobile: 'App Push'
  },
  inspectionRecords: {
    insorder: 'Inspection Orders',
    insorderTips: 'View Inspection/Patrol Details',
    insorderQueryTips: 'Search by Inspection Order Num, Description or Inspector',
    status: 'Status',
    inspOrder: 'Inspection Order',
    recNum: 'No.',
    description: 'Description',
    dateTime: 'Date Time',
    insPlanTime: 'Plan Inspection Time',
    actStartTime: 'Actual Start Time',
    actComTime: 'Actual Completion Time',
    overTime: 'Time Out',
    assignee: 'Assignee',
    insMan: 'Assignee',
    unspec: 'Unspecified',
    actExecutor: 'Inspector',
    reviewer: 'Reviewer',
    homeReviewed: 'reviewed',
    track: 'Process',
    inspResults: 'Inspection Results',
    viewDetail: 'View Detail',
    viewInstaskDetail: 'View Task',
    viewMapTrack: 'View Track Trail',
    trackEmptyTips: 'No Track Trail',
    rcEndedCompleted: 'End(Completed)',
    end: 'End',
    comp: 'Completed',
    wfProcess: 'WaitingStart',
    inProgress: 'InProgress',
    pending: 'OnHold',
    cancelled: 'Cancelled',
    rcunprocess: 'Pending',
    rcAbnormal: 'Abnormal',
    rcOmitLoc: 'OmitLoc',
    increport: 'Incident Report',
    rcWorkOrder: 'Work Order',
    rcNotTimeRange: 'NotPlanedTime',
    selectStartDate: 'Select Start Date',
    selectEndDate: 'Select End Date',
    allInslist: 'All Inspection Orders',
    nearlyaweekinslist: 'Last 7 Days',
    nearlyonemonthinslist: 'Last 30 Days',
    nearlyhreemonthsinslist: 'Last 90 Days',
    recordDate: 'Record Date',
    createNewTask: 'Create New Inspection Plan',
    emptyTips: 'No relevant inspection order found.',
  },
  inspectionRecordsDetail: {
    viewinsListDetail: 'View Detail',
    reportPrint: 'Report Printing',
    printins: 'Inspection Work Order',
    printinsExc: 'Inspection List (Abnormals Only)',
    insNum: 'Inspection Order No.',
    insDesc: 'Inspection Description',
    inscreateTime: 'Inspection Order Generation Time',
    actStartTime: 'Actual Start Time',
    insplanTime: 'Plan Inspection Time',
    actcomtime: 'Actual Completion Time',
    overMinToComp: 'Over {count} Min',
    completeStatus: 'Completion Status',
    normal: 'Normal',
    abNormal: 'Abnormal',
    setAbnormal: 'Set to Abnormal',
    normalChangeBy: 'Changed By',
    normalChangeDate: 'Changed Time',
    changeNormalReason: 'Reason for Change',
    insman: 'Assignee',
    multipleExecute: 'Allow Multiple People Collaboration?',
    unspec: 'Unspecified',
    actExecutor: 'Inspector',
    reviewer: 'Reviewer',
    homeReviewed: 'reviewed',
    mileage: 'Patrol Mileage',
    kilo: 'Km',
    cancelReason: 'Reason for Cancel',
    partinsReason: 'Reason for Partia lnspection',
    increportnum: 'Incident No.',
    increportcontent: 'Incident Report Content',
    reporttime: 'Report Date',
    inccomptime: 'Completed Date',
    handleResult: 'Result',
    status: 'Status',
    detail: 'Detail',
    withIncreps: 'With {count} Incident Reports',
    insfeedback: 'Inspection Feedback',
    deleteLocation: 'The inspection point has been deleted',
    nonpat: 'Not Inspected',
    insAbnormal: 'Abnormity',
    locRcWoHitsory: `This abnormal inspection point has created {count} work order`,
    skipReasonTips: 'Skipped',
    situafeedback: 'Feedback',
    arrivalTime: 'Planned Arrival Time',
    skipReason: 'Reason for Skip',
    reviewSkipReason: 'Reason for reviewer skip',
    gpsReason: 'Reason for skipping GPS position',
    registration: 'Planned Schedule',
    earlyMinutes: 'Earlied By',
    lateMinutes: 'Delayed By',
    reason: 'Reason',
    reviewReason: 'Review Reason',
    inputReason: 'Please enter the reason',
    allInspat: 'All Checking Point',
    onlyAbnormal: 'Show Only Abnormlities Point',
    onlyPatrolled: 'Show Only Inspected Points (Including Abnormalities)',
    onlyUnpat: 'Show Only Uninspected Points',
    position: 'Inspection Point',
    patPointName: 'Inspection Point Name',
    patPointNameTips: 'Name of the Inspection Point, use to define the physical location or asset to inspect',
    patPointNum: 'Inspection Point No.',
    patPointNumTips: 'Numbering of Inspection Point',
    patPointType: 'Label Type',
    patPointTypeTips: 'Types of Inspection Points, it can be NFC, QR code, Bluetooth or GPS',
    errorRange: 'Tolerance',
    errorRangeUnit: 'Meter',
    mapPoint: 'Inspection Point GPS',
    lng: 'Lng',
    lat: 'Lat',
    isUseLabel: 'Must Scan Tag/QRCode on Arrival',
    isUseLabelTips: 'When enabled, require the inspector to scan the Tag/QRCode upon arrival to indicate physical presence',
    isEffective: 'Enabled',
    isEffectiveTips: 'Once unchecked, this Inspection Point will be excluded in the new generated Inspection Orders and Work Orders',
    viewImgDigLog: 'Picture Details',
    viewWOrDigLog: 'Work Order History',
    woNum: 'Work Order No.',
    woDesc: 'Work Order Description',
    woCreateTime: 'Work Order Generation Time',
    sponsor: 'Creator',
    signature: 'Signature',
  },
  signInList: {
    signInList: 'Punch-in Records',
    createSignInTemplate: 'Create Punch-in Template',
    enterSearch: 'Search by Inspection Point / Login Account',
    signInTemplateApplyNo: 'Transaction ID',
    signInTemplateInputName: 'Punch-in Template',
    signInTemplatePatrolPointName: 'Inspection Point',
    signInTemplatePunchTime: 'Expected Punch-in Time',
    signInTemplatePatrolTime: 'Actual Punch-in Time',
    type: 'Type',
    alertWarning: 'Alerts/Warnings',
    taskTemplateLoginName: 'Login Account',
    signInTemplateLockUser: 'Inspector',
    emptyTips: 'No relevant punch-in found.',
  },
  sensorReading: {
    monitorList: 'IoT Readings',
    sensorCode: 'IoT Sensor code',
    sensorName: 'IoT Sensor name',
    sensorDesc: 'Description',
    sensorQueryTips: 'Search by IoT Sensor Code, Name or Description',
    instrumentReadingRecode: 'Historical data collected by IoT sensors.',
    emptyTips: 'No relevant IoT sensor found.',
  },
  sensorReadingDetail: {
    monitorList: 'IoT Readings',
    securitySensorsTit: 'IoT Sensor Name',
    sensorCode: 'IoT Sensor code',
    sensorName: 'IoT Sensor name',
    monitorNum: 'Monitor Template Number',
    monitorName: 'Monitor Name',
    sensorDesc: 'Description',
    meterPic: 'Photos',
    sensorPicError: 'Failed to load',
    monitorIndices: 'Instrument index',
    monitorIndicesNum: 'Measured value',
    monitorIndicesUnit: 'Unit',
    monitorIndicesDifference: 'Difference value',
    monitorIndicesDate: 'Time',
    meter: 'Meter',
  },
  event: {
    eventreplist: 'Incident Reports',
    eventreplistTips: 'View and Track Incident Reports During Inspection',
    incNum: 'No.',
    content: 'Content',
    position: 'Inspection Point',
    reporter: 'Report By',
    reportTime: 'Report Date',
    priority: 'Priority',
    results: 'Report Results',
    handler: 'Handler',
    handlerResult: 'Result',
    eventreplistQueryTips: 'Search by Incident Report No., Content, Inspection Point or Assignee',
    showWep: 'Show Outstanding Incidents',
    setEmailNotice: 'Set Email Notification',
    setEmailNoticeTips: 'After the email notification is set, when an incident report is created, the system will send it to the following people by email. You can allow multiple mailboxes to be set, separated by commas.',
    setEmailNoticeSuchTips: 'such as: abc@313fm.com, lucy@313fm.com, tom@313fm.com',
    emailAddress: 'Email Address',
    eamilAddressError: 'Email address format is incorrect',
    allIncreport: 'All Incident Reports',
    nearlyaweekinc: 'Incidents (Last 7 Days)',
    nearlyOneMonthinc: 'Incidents (Last 30 Days)',
    nearlyThreeMonthsInc: 'Incidents (Last 90 Days)',
    setEmailSuccess: 'The Email has been saved successfully!',
    viewDetail: 'View Detail',
    reportResults: 'Quick Report Results',
    comp: 'Completed',
    can: 'Cancelled',
    wfp: 'Wait for process',
    emptyTips: 'No relevant incident report found.',
  },
  eventDetail: {
    viewIncReportDetail: 'View Incident Report',
    increportNum: 'Incident No.',
    increportDesc: 'Incident Content',
    increportPos: 'Point',
    reporter: 'Report By',
    reportTime: 'Report Date',
    cancelTime: 'Cancel Date',
    incComptime: 'Completed Date',
    handleResult: 'Result',
    dealerout: 'Handler',
    increportesultman: 'Change By',
    increportTime: 'Change Date',
    increportImg: 'Incident Photo',
    increportVideo: 'Incident Video',
    increportAudio: 'Incident Audio',
    increportResult: 'Report Results',
  },
  eventCollect: {
    increportDesc: 'Incident Content',
    incComptime: 'Completed Date',
    handleResult: 'Result',
    dealerout: 'Handler',
    handleComp: 'Mark Completed',
    notNeddCancel: 'Cancel',
  },
  dataList: {
    datacolrecord: 'Data / Meter Readings',
    datacolrecordTips: 'Historical data records collected via Inspection Orders.',
    datacolrecordQueryTips: 'Searcy by Inspection Point or Data / Meter Reading Item',
    patPointLoc: 'Inspection Point',
    dataColitem: 'Data / Meter Reading Item',
    deleteLocation: 'The inspection point has been deleted',
    emptyTips: 'No relevant data / meter reading found.',
    view: 'View Data / Meter Reading',
  },
  dataListDetail: {
    title: 'Data / Meter Reading Detail',
    patPointLoc: 'Inspection Point',
    datacolitem: 'Data / Meter Reading Item',
    datacolTime: 'Reading Time',
    datacolVal: 'Values',
    inslist: 'Inspection Order',
    instask: 'Inspection Plan',
    emptyTips: 'No relevant data found.',
  },
  alertHistory: {
    rcAlert: 'Alert Records',
    alert: 'Alert',
    recnum: 'No.',
    desc: 'Description',
    executor: 'Assignee',
    alertLoc: 'Alert Location',
    alertMsg: 'Alert Content',
    alertTime: 'Alert Time',
    results: 'Results',
    handler: 'Handler',
    handleContent: 'Handle Note',
    handleDate: 'Handle Time',
    rcAlertSearchEnterTips: 'Search by Inspection Order / Work Order / Description / Alert Content / Assignee',
    selectStartDate: 'Select Start Date',
    selectEndDate: 'Select End Date',
    viewDetail: 'View Detail',
    viewinsListDetail: 'View Detail',
    viewWoDetail: 'View Work Order',
    handleAlertInfo: 'Follow Up',
    processed: 'Processed',
    unprocessed: 'Unprocessed',
    emptyTips: 'No relevant alert found.',
  },
  alertHistoryHandleAlerts: {
    handleAlertInfo: 'Alert Follow-Up',
    insDesc: 'Inspection Description',
    alertLoc: 'Alert Location',
    alertMsg: 'Alert Content',
    handleDate: 'Handle Time',
    handleContent: 'Handle Note',
    handler: 'Handler',
    incresultover: 'Content Cannot Exceed 100 Chacters',
    placeholderHandler: 'The handler is a mandatory field',
  },
  alertHistoryDetail: {
    viewRcAlert: 'Alert Details',
    alertNum: 'Alert No.',
    insDesc: 'Inspection Description',
    insMan: 'Assignee',
    alertLoc: 'Alert Location',
    alertTime: 'Alert Time',
    alertMsg: 'Alert Content',
    handler: 'Handler',
    handleDate: 'Handle Time',
    handleContent: 'Handle Note',
  },
  guestQrcode: {
    guestQrcode: 'Entrance Management',
    guestManageDescription: 'Visitor Entrance of this Property Entity, e.g. Door# 1 of XX Building',
    enterSearch: 'Search by Entrance Name',
    addGuestQrcode: 'Create New Visitor Entrance',
    locTips: 'Please select inspection point',
    configUserName: 'Entrance Name',
    shareByEmail: 'Share by Email',
    shareByEms: 'Share by SMS',
    emailAddress: 'Email Address',
    emailSetErrMsg: 'Please enter the correct email address',
    inputAndEnter: 'Please enter the content and press enter, or then click send',
    sendSuccess: 'Sent Successfully',
    secondresend: `Resend after {seconde} seconds`,
    correctAlertPhone: 'Please input the correct alertphone',
    selPhoneNum: 'Mobile Phone',
    detail: 'Detail',
    guestBookIngUrl: 'Appointment registration link',
    entranceQrcodeTips: 'To facilitate the visitor to scan the QR code and fill-in their information, please print and display the QR code at the entrance.',
    homeLoc: 'Inspection Point',
    visitorQuestionTmpl: 'Questionnaire',
    emptyTips: 'No relevant visitor entrance found.',
    shareTips: 'The visitor appointment information has been changed, please save it before sharing',
  },
  guestReserve: {
    guestReserve: 'Visitor Appointments',
    guestReserveDescription: 'All visitor appointment bookings would be recorded here.',
    pleaseSelectStatus: 'Status',
    guestReserveName: 'Visitor',
    guestReserveTime: 'Appointment Time',
    guestCompany: 'Visitor Company',
    contactPerson: 'Name of Host',
    guestCount: 'Visit Count',
    guestStatus: 'Status',
    startDate: 'Start Date',
    endDate: 'End Date',
    guestCompanyNullMsg: 'Search by Company or Visitor Name',
    confimCancelGuest: 'Cancel Appointment?',
    cancelSuccess: 'Visit reservation cancelled successfully!',
    guestReserveDetail: 'Appointment Details',
    guestLocalAddress: 'Visitor Location',
    guestName: 'Visitor',
    guestBookingTime: 'Appointment Time',
    guestPhone: "Visitor's Phone Number",
    guestIdCard: 'Identification No. (First 5 Digit)',
    guestMember: "Visitor's Companions",
    depVisitedComplany: 'Company / Department being visited',
    guestPurpose: 'Purpose of Visit',
    guestContact: 'Name of Host',
    guestContactPhone: 'Telephone No. of Host/Visitee',
    guestQrcodeValidTime: 'QR Code Validity Period',
    guestQrcodeValidTimepl: 'Visit registration QR code is only valid within that period.',
    visitQrcode: 'Visit QRCode',
    emptyTips: 'No relevant visitor appointment found.',
  },
  guestHistory: {
    visitorQuestionnair: 'Questionnaire for Visitor',
    visitHistory: 'Visitor Records',
    visitHistoryDescription: "History of registered visitor's QR code verification records",
    guestCompanyNullMsg: 'Search by Visitor Name or Company ',
    guestReserveName: 'Visitor',
    guestReserveTime: 'Appointment Time',
    visitTime: 'Arrival Time',
    guestCompany: 'Visitor Company',
    contactPerson: 'Name of Host',
    isEntryAllow: 'Entered?',
    isAllow: 'Allowed',
    isNotAllow: 'Not Allowed',
    setting: 'Retention Setting',
    keepRecordsByDay1: 'Keep only ',
    keepRecordsByDay2: ' days records',
    daysNoNull: 'The number of days is a mandatory field',
    pleaseInputMinMaxNumber: `Please enter an integer between {min} and {max}`,
    editSuccess: 'Successfully Updated',
    emptyTips: 'No relevant visitor found.',
  },
  guestRegister: {
    guestBooking: 'Visitor Appointment',
    guestRegister: 'Visitor Registration',
    guestBookingTimeStartNullMsg: 'Please select a date',
    guestBookingTimeEndNullMsg: 'Please select a time',
    guestNameNullMsg: 'Visitor is a mandatory field',
    guestFormLengTherrmsg: `Between {min} and {max} characters in length`,
    guestCompanyNullMsg: 'Please enter the name of the visitor company',
    guestAddressNullMsg: 'Address is a mandatory field',
    guestDepVisitedCompanyMsg: 'Company / Department being visited is a mandatory field',
    guestPurposeNullMsg: 'Purpose of Visit is a mandatory field',
    guestContactNullMsg: 'Name of Host is a mandatory field',
    guestContactPhoneNullMsg: 'Telephone No. of Host/Visitee is a mandatory field',
    guestQrcodeValidTimeNullMsg: 'Please select the validity period of the QR code',
    qrCodeTimeoutMsg: 'The expiration date of the QR code cannot be earlier than the scheduled time',
    guestBookingTime: 'Appointment Time',
    pleaseSelectDate: 'Select Date',
    pleaseSelectTime: 'Select Time',
    guestName: 'Visitor Name',
    guestPhone: "Visitor's Phone Number",
    guestIdCard: 'Identification No. (First 5 Digit)',
    guestMember: "Visitor's Companions",
    guestMemberpl: 'Name(s) and contact information of the visitor group',
    guestCompany: 'Visitor Company',
    depVisitedCompany: 'Company / Department being visited',
    guestPurpose: 'Purpose of Visit',
    guestContact: 'Name of Host',
    guestPhotosTips: 'Only photos in .jpg, .jpeg, and .png formats can be uploaded and should not exceed 8MB.',
    guestContactPhone: 'Telephone No. of Host/Visitee',
    guestQrCodeValidTime: 'QR Code Validity Period',
    to: 'To',
    startDate: 'Start Date',
    endDate: 'End Date',
    guestQrcodeValidTimepl: 'Visit registration QR code is only valid within that period.',
    checkGuestQrcode: 'View the appointment QR code',
    repeatSubimtFailMsg: 'Submission with the same information is not allowed. Please update at least one item before resubmitting.',
    visitregisterSuccess: 'Registration confirmed',
    guestQrCode: 'Entrance Management',
    bookingSuccess: 'Appointment confirmed',
    bookingSuccessDescription: 'Please picture the QRCODE and send to the visitor for safekeeping, visitor shall present the QR',
  },
  workTableLog: {
    gwrzlb: 'Duty Logbook',
    gwrzDesc: 'Daily activities of each post could be recorded in this duty logbook.',
    searchLog: 'Search by Location, Detail or Inspector',
    selectStartDate: 'Please Select Start Date',
    selectEndDate: 'Please Select End Date',
    logStartDate: 'Event Start Time',
    logEndDate: 'Event End Time',
    logSource: 'Source',
    logContentDetail: 'Event Details',
    logLoc: 'Location',
    logExeName: 'Inspector',
    logInputTime: 'Record Time',
    createLog: 'Create Log',
    workOrder: 'Work Order',
    inspectionForm: 'Inspection Order',
    jobLog: 'Post Log',
    eventReport: 'Incident Report',
    emptyTips: 'No relevant duty logbook found.',
  },
  workTableLogCreate: {
    addLog: 'Add Duty Log',
    editLog: 'Edit Duty Log',
    logDetail: 'Duty Logbook Details',
    logStartDate: 'Event Start Time',
    logEndDate: 'Event End Time',
    logLoc: 'Location',
    logContentDetail: 'Event Details',
    logPic: 'Photo',
    logPics: 'Photos',
    logHr: 'Executor Account',
    logHrName: 'Executor Name',
    logInputTime: 'Record Time',
    logType: 'Log Type',
    logSource: 'Source',
    logOddNumbers: 'Order Num',
    logPatPonit: 'Inspection Point',
    pleaseSelectOrInput: 'Please select or input the event type',
    assetsName: 'Asset Name',
    homeLoc: 'Inspection Point',
    logPlace: 'Inspection Point',
    logStartTime: 'Inspection Time',
    nullTips: 'Please select or enter the required box with *',
    createSuccess: 'The new dut logbook has been created successfully!',
    editSuccess: 'The dut logbook has been edited successfully!',
  },
  fileUpload: {
    documentationLibrarylist: 'Document Library',
    documentationDetails: 'Published documents can be accessed by mobile app or web site.',
    fileName: 'File Name',
    fileDescription: 'File Description',
    fileType: 'Category',
    fileAttributeEntity: 'Property Entity',
    fileTargetUserGroup: 'Target User Groups',
    filePostToEveryone: 'Publish to Everyone',
    filePushNotification: 'Push Notification',
    fileUserConfirmationIsRequired: 'User Confirmation Required',
    fileReleaseTime: 'Publish Time',
    allUsers: 'All users',
    fileUploadEnterRsearch: 'Search by Filename / Description',
    deleteSuccess: 'The document has been deleted successfully!',
    withdrewSuccess: 'The document has been withdrew successfully!',
    releaseSuccess: 'The document has been released successfully!',
    confirmWithdrawFile: 'Please confirm whether to withdraw File',
    confirmReleaseFile: 'Publish the file to target users?',
    withdraw: 'Withdraw',
    release: 'Release',
    emptyTips: 'No relevant document found.',
  },
  fileUploadPreview: {
    uploareadTip: 'Read the content, please click OK',
    uploadAutographName: 'Username',
    namePlh: 'Please enter the name of the reader',
    actexecutorRequried: 'Username is mandatory',
  },
  fileUploadCreate: {
    createDocumentationLibrary: 'Create file',
    editDocumentationLibrary: 'Edit file',
    fileName: 'File Name',
    uploadFilesSync: 'Upload file to synchronize name',
    fileDescription: 'File Description',
    fileType: 'Category',
    pleaseSelectCategory: 'Please select a category',
    fileAttributeEntity: 'Property Entity',
    fileTargetUserGroup: 'Target User Groups',
    fileUserConfirmationIsRequired: 'User Confirmation Required',
    filePushNotification: 'Push Notification',
    allUsers: 'All users',
    selectWorkGroup: 'Select Workgroup(s)',
    everyPushTime: 'Every {day} Days, Push up to {resendnum} times.',
    doNotPush: 'Do Not Push',
    pushOnce: 'Push Once',
    everyDay: 'Every day',
    pleaseUploadFile: 'Please upload the file',
    pleaseFileContent: 'File Description is a mandatory field',
    pleaseSelectResendNum: `The number of push retransmissions cannot exceed {resendnum}`,
    pleaseSelectResendNumNull: `Push resend times cannot be 0`,
    createSuccess: 'The document has been created successfully!',
    editSuccess: 'The document has been edited successfully!',
  },
  fileUploadTable: {
    fileUploadList: 'Document Access Records',
    fileTableEnterRsearch: 'Search by Description / Category',
    fileName: 'File Name',
    fileDescription: 'File Description',
    fileAttributeEntity: 'Property Entity',
    fileUploadUser: 'Username',
    fileUploadOpenDate: 'First Access Datetime',
    fileUploadConfirmDate: 'Read Datetime',
    fileUploadOpenNum: 'Access Count',
    emptyTips: 'No relevant document found.',
  },
  fileUploadTableDetail: {
    uploadHistoryList: 'Historical details',
    uploadExecutor: 'User',
    uploadViewTime: 'Access Time',
    accessRecords: 'Access Records',
  },
  fileUploadMyHistory: {
    title: 'My Documents',
    all: 'All',
    read: 'Read',
    unread: 'Unread',
    fileDescription: 'File Description',
    fileType: 'Category',
    fileUserConfirmation: 'User Confirmed',
    fileReleaseTime: 'Publish Time',
    fileUploadRead: 'Read',
  },
  selectPunchIn: {
    selectPunchInTemplate: 'Select Punch-in template',
    punchInTemplateSearch: 'Search by Punch-in Template Name',
    punchInTemplateInputName: 'Punch-in Template Name',
    punchInTemplateNum: 'Punch-in template No.',
  },
  selectRcWo: {
    inslist: 'Inspection Order',
    patNum: 'Inspection Order No.',
    insplanTime: 'Plan Inspection Time',
    executor: 'Assignee',
    enterSearch: 'Search by Content',
  },
  rptCenter: {
    rptCenter: 'Report Center',
    rptCenterTips: 'If existing reports do not meet your business needs, you may propose your suggestion by sending to support@samexsys.com for our consideration.',
    rptNameTips: 'Search by Report Name',
    rptName: 'Report Name',
    rptView: 'Report Preview',
    runCount: 'Execution Count',
    classification: 'Classification',
    classificationPlh: 'Select Classification',
    nameSort: 'Sort by Report Name',
    frequencySort: 'Sort by Execution Count',
    run: 'Run',
    runRpt: 'Run Report',
    runRptTitle: 'Run Report ({name})',
    autoEmailSetting1: 'Auto-Email',
    autoEmailSetting2: 'Setting',

    runFieldRequired: 'The {field} is a mandatory field',
    yearMonthDay: 'Year-Month-Day',
    yearMonth: 'Year-Month',
    rptYear: 'Year',
    startDate: 'Start Date',
    endDate: 'End Date',
    startEndDateTime: 'Start and End Periods',
    startEndMonth: 'Start and End Months',
    insman: 'Assignee',
    executor: 'Assignee',
    patPointLoc: 'Inspection Point',
    woTemplate: 'Work Order Template',
    punchInTemplate: 'Punch-in Template',
    locNoPatrol: 'Inspection points not inspected',
    newCreate: 'New Create',
    patPointName: 'Inspection Point Name',
    patPointNum: 'Inspection Point No.',
    checkOutPhoti: 'Export photos',
    fileName: 'File Name',
    contentDescription: 'Content Description',
    noout: 'No need to output inspection items',
    fileUploadUser: 'Username',
    logSource: 'Source',
    accountGroup: 'Work Group',
    iffy: 'Paging by Inspection Point',
    instask: 'Inspection Plan',
    homeLoc: 'Inspection Point',
    rcWoStatus: 'Inspection Order Status',
    showGpsCheckbox: 'Whether to display gps coordinates',
    showDescCheckbox: 'Time Desc',
    taskStartDate: 'Start time of inspection order plan',
    taskEndDate: 'End time of inspection order plan',
    taskStartEndDateTime: 'Plan Start and End Periods',
    // stentValDate: 'Select either the Planned Inspection Order start and end time, or the Inspection Order actual completion start and end time',
    stentValDate: 'Please select the planned start and end periods or the actual start and completion periods',
    taskFinishStartDate: 'Actual start time',
    taskFinishEndDate: 'Actual completion time',
    actualStartCompletionDateTime: 'Actual Start and Completion Periods',
    rptparamsfb: 'Feedback Results Only',
    abnormalPonitOnly: 'Abnormal Point Only',
    rptExestatus: 'Execution Status',
    rptExestatusNoComp: 'Only Unfinished',
    rptExestatusComp: 'Only Finished',
    missingPointPrompt: 'Missing Point Prompt',
    onlyerrcwo: 'Only display inspection order containing incident reports',
    inslist: 'Inspection Order',
    result: 'Result',
    isShowPic: 'Display Inspection Photos',
    isShowEventReport: 'Display Incident Reports',
    isShowDetail: 'Display Details',

    // 报表中心自定义参数
    rpcustomparam: 'Customization Parameters',
    rpwonum: 'Work Order No.',
    rpwodesc: 'Word Order Description',

    emptyTips: 'No relevant report found.',
  },
  rptCenterTemplateCreate: {
    title: 'Report ({name})',
    description: 'Description',
    tempLang: 'Language',
    tempParams: 'Report Parameter',
    createTempTips: 'Please enter the description',
    autoMailSet: 'Auto-Email Setting',
    emailSubject: 'Email Subject',
    sendCycle: 'Schedule',
    cycled: 'Every Day',
    cyclew: 'Every Monday',
    cyclem: 'Every Month (1st)',
    schedule: 'Report Delivery Time',
    sendTime: 'Target Data Period',
    sendTimeDayBefore: 'Previous Day',
    sendTimeLastWeek: 'Previous Week',
    sendTimeLastMonth: 'Previous Month',
    sendTimeCustomTime: 'Custom time period',
    dayBefore: 'Previous Day',
    today: 'Today',
    weekAgoDay: 'A week ago that day',
    setEmail: 'Email',
    emailSetErrMsg: 'Please enter the correct email address',
    emailSetErrMsg2: 'Enter at least one email address',
    lessThan24HoursTips: 'The time difference must be less than 24 hours',
    endTimeLessThanStartTimeTips: 'The end time must be later than the start time',
  },
  rptCenterTemplate: {
    searchByDescription: 'Search by description',
    description: 'Description',
    creator: 'Creator',
    create: 'Report Delivery by Email',
  },
  rcwosum: {
    insexeeffTitle: 'Inspection Efficiency Dashboard',
    nearlySevenDays: 'Last 7 Days',
    nearlyThirtyDays: 'Last 30 Days',
    nearlyNinetyDays: 'Last 90 Days',
    thisMonth: 'Month-to-Date',
    lastMonth: 'Last Month',
    thisQuarter: 'Quarter-to-Date',
    lastQuarter: 'Last Quarter',
    thisYear: 'Year-to-Date',
    prevYear: 'Last Year',
    statusTitle: 'Inspeciton Order by Status',
    xAxis: 'Schedule Date',
    yAxis: 'Inspection Order Count',
  },
  eventsum: {
    eventRepstatiTitle: 'Incident Report Dashboard',
    nearlySevenDays: 'Last 7 Days',
    nearlyThirtyDays: 'Last 30 Days',
    nearlyNinetyDays: 'Last 90 Days',
    thisMonth: 'Month-to-Date',
    lastMonth: 'Last Month',
    thisQuarter: 'Quarter-to-Date',
    lastQuarter: 'Last Quarter',
    thisYear: 'Year-to-Date',
    prevYear: 'Last Year',
    xAxis: 'Report Date',
    yAxis: 'Count',
  },
  responseTimeDashboard: {
    responsetimeTitle: 'Work Order Response Time Dashboard',
    responSetimeItemTitle: 'Response Time',
    wocatSelectTips: 'Select Classification',
  },
  locsum: {
    patabnstaTitle: 'Abnormality Locations Dashboard',
    topTen: 'Top 10',
    xAxis: 'Inspection Time',
    yAxis: 'Number of Abnormality Locations',
  },
  hrsum: {
    staffeffTitle: 'Human Efficiency Dashboard',
  },
  releaseHistory: {
    releaseHistory: 'Version History',
    updateStatisics: 'Update statistics',
    lastUpdateDate: 'Latest update date',
    updateNum: 'Update times in the past year',
    updateRecords: 'Update records',
    updateDate: 'Update date',
    updateLog: 'Update Log',
    showInfo: 'Display Information',
    lastMonth: 'Last Month',
    lastThreeMonth: 'Last Three Months',
    halfaYear: 'Nearly Half A Year',
    lastYear: 'Last Year',
  },
  // 安全规则
  securityRules: {
    securityRulesTitle: 'Safety Guard',
    securityRulesTitleTips:
      "The Safety Guard monitors personnel's working status in real-time. If there is no movement within a set time (e.g., 10 minutes), the system calls the target person for a period (e.g., 2 minutes). If he/she is alerted, a gentle phone shake restores normalcy; if not, the system alerts the backend (by displaying on Inspection Monitoring). This feature greatly enhances the job safety of on-site workers, including cleaners, security guards, engineers, and solo workers.",
    enterSearch: 'Search by Safety Guard',
    createSecurityRules: 'Create Safety Guard',
    editSecurityRules: 'Edit Safety Guard',
    securityRulesName: 'Safety Guard',
    groupNumberOfHr: 'Number of Members',
    securityMonitoringInterval: 'No Movement Threshold Time',
    securityMonitoringDelay: 'Waiting Response Threshold Time',
    securityLastName: 'Last modified by',
    securityLastTime: 'Last modification time',
    confirmDelete: 'Confirm to delete the record?',
    deleteSuccess: 'The Safety Guard has been deleted successfully!',
    emptyTips: 'No relevant safety guard found.',
  },
  securityRulesCreate: {
    securityRulesName: 'Safety Guard',
    securityRulesNameTips: 'Safety Guard is a mandatory field',
    securityRulesModel: 'Activate Upon',
    securityRulesModelLogin: 'Sign-In',
    securityRulesModelLoginTips: 'Start monitoring after signing in, till signing out',
    securityRulesModelRcwo: 'Start of Inspection/Work Order',
    securityRulesModelRcwoTips: 'Start monitoring after first inspection point of any Inspection Order or Work Order started, till all Inspection Orders and Work Orders are done',
    securityInterval: 'When no movement for {interval1} minute(s), automatically alert the phone holder for {interval2} minute(s).',
    securityAutoRemindMethod: 'If no response, then alert backend in following ways:',
    securityInspmntr: 'Safety Incident (Flashing & Playing Sound)',
    voiceMsgTips: 'Voice Call',
    smsMsgTips: 'SMS',
    protectionList: 'Notice To',
    protectionListTips: 'Please select the notified person',
    phoneIsEmptyTips: 'Phone number is empty, please set the phone number first',
    selectExecutor: 'Member',
    phone: 'Phone',
    createSuccess: 'The Safety Guard has been created successfully!',
    editSuccess: 'The Safety Guard has been edited successfully!',
    securityMonitoringIntervalRangeTips: 'No movement threshold time range is 5 to 60 minutes',
    securityMonitoringIntervalEmptyTips: 'No movement threshold time is a mandatory field',
    securityMonitoringDelayRangeTips: 'The waiting response threshold time should be less than the no movement threshold time',
    securityMonitoringDelayEmptyTips: 'The waiting response threshold time is a mandatory field',
  },
  securityRulesMember: {
    groupMember: 'Member',
    search: 'Search by Username or Account Owner',
    addGroupMenber: 'Add Member',
    deleteSuccess: 'The Member has been deleted successfully!',
    createSuccess: 'The Member has been created successfully!',
  },
  // 常用理由管理
  commonReason: {
    commonReasonTitle: 'Frequently Used Reason',
    commonReasonSearchTip: 'Search by Reason',
    commonReasonDesc: 'Description',
    commonReasonType: 'Classification',
    commonReasonAdd: 'Add reason item',
    commonReasonEdit: 'Edit reason item',
    commonReasonTypeSelectPlh: 'Select Classification',
    commonReasonTypeSelectTip: 'Please select classification',
    commonReasonSystemTitle: 'System default reason',
    deleteSuccess: 'The Common Reason has been deleted successfully!',
    commonReasonInputDescTip: 'Description is a mandatory field',
    commonReasonDescOver100: 'Cannot exceed 100 characters',
    createSuccess: 'The Common Reason has been created successfully!',
    editSuccess: 'The Common Reason has been edited successfully!',
    emptyTips: 'No relevant frequently used reason found.',
    selectTips: 'Please select reason',
  },
  // SLA
  sla: {
    slaTitle: 'SLA (Service Level Agreement) Setting',
    slaSearchPlhld: 'Search by Priority',
    slaDescLabel: 'Priority',
    slaFbtLabel: 'Target Response Time (Minutes)',
    slaDescLongLabel: 'Priority of SLA configuration',
    slaAddTitle: 'Add SLA Setting',
    slaEditTitle: 'Edit SLA Setting',
    numberTips: 'Please enter a number',
    slaEmptyTips: 'Mandatory field',
    slaMaximumTips: 'Cannot be greater than 32000',
    createSuccess: 'The SLA has been created successfully!',
    editSuccess: 'The SLA has been edited successfully!',
    emptyTips: 'No relevant service level agreement found.',
  },
  // 访客问题管理
  visitorQuestion: {
    visitorQuestionTitle: 'Visitor Question',
    visitorQuestionBankTitle: 'Question List',
    searchTips: 'Search by Question',
    visitorQuestionAdd: 'Add Question',
    visitorQuestionEdit: 'Edit Question',
    visitorQuestionTypeSc: 'Single Choice',
    visitorQuestionTypeMc: 'Multiple Choice',
    visitorQuestionTypeIn: 'Fill in the blank',
    visitorQuestionInputtypeTxt: 'Text',
    visitorQuestionInputtypeNum: 'Number',
    visitorQuestionInputtypeDate: 'Date and Time',
    visitorQuestionTopicTips: 'Question is a mandatory field',
    visitorQuestionTopicTypeTips: 'Please select the question type',
    visitorQuestionInputTypeTips: 'Please select the fill-in type',
    visitorQuestionNumTips: 'Please enter a number',
    visitorQuestionSeqTips: 'Sequence No. is a mandatory field',
    visitorQuestionOption200Tips: 'Each item cannot exceed 200 characters',
    visitorQuestionOptionalInputTips: 'Content is a mandatory field',
    visitorQuestionOptionalTips: 'Please add optional items',
    visitorQuestionAddToBank: 'Add to Question List',
    createSuccess: 'The question has been added successfully!',
    editSuccess: 'The question has been edited successfully!',
    deleteSuccess: 'The question has been deleted successfully!',
    emptyTips: 'No relevant question found.',
  },
  // 访客问题模板
  visitorQuestionTmpl: {
    visitorQuestionTmplTitle: 'Questionnaire for Visitor',
    visitorQuestionTmplSearchTips: 'Search by Questionnaire Name / Description',
    visitorQuestionTmplName: 'Questionnaire Name',
    visitorQuestionTmplDesc: 'Description',
    visitorQuestionTmplAdd: 'Add Questionnaire',
    visitorQuestionTmplEdit: 'Edit Questionnaire',
    visitorQuestion200Tips: 'Cannot exceed 200 characters',
    valueFormal: 'Please enter a number',
    pleaseFillIn: 'Please Fill In',
    pleaseSelect: 'Please Select',
    visitorQuestionInputTypeDateTips: 'Please select date and time',
    visitorQuestionList: 'Questions',
    visitorQuestionAdd: 'Add Question',

    visitorQuestionSelect: 'Select from Question List',

    visitorQuestionSeq: 'Sequence No.',
    visitorQuestionTopic: 'Question',
    visitorQuestionTopicType: 'Question Type',
    visitorQuestionFillType: 'Fill Type',
    visitorQuestionRequired: 'Mandatory?',
    visitorQuestionTmplNameTips: 'Questionnaire Name is a mandatory field',
    visitorQuestionTmplDescTips: 'Description is a mandatory field',
    createSuccess: 'The Questionnaire for Visitor has been created successfully!',
    editSuccess: 'The Questionnaire for Visitor has been modified successfully!',
    deleteSuccess: 'Successfully deleted Questionnaire for Visitor!',
    visitorQuestionOptional: 'Optional',
    visitorQuestionAddOptional: 'Add Option',
    emptyTips: 'No relevant questionnaire found.',
  },
  // 巡检监控
  inspMn: {
    title: 'Inspection Monitoring',
    hiddenChartLabel: 'Hide Charts',
    todayCheckPntComp: 'Inspection Order Completion Rate (Today)',
    todayCheckPntCompTips1: `On the last day of the inspection sheet cycle, completing it is considered "on-time." If it's completed before the last day, it's considered "ahead of schedule".`,
    todayCheckPntCompTips2: `The completion rate for the day is calculated by dividing the number of currently completed orders (excluding those completed after the deadline) by the total number of orders completed ahead of schedule or on time.`,
    todayCheckPntCompTooltip1: 'The number of orders completed on the same day (excluding those completed after the deadline)',
    todayCheckPntCompTooltip2: 'The total number of orders completed ahead of schedule or on time',
    checkPntChartTitle: 'Completion',
    weeklyWo: 'Work Orders (Last 7 Days)',
    weeklyInspPntComp: 'Top 10 Inspection Points (Last 7 Days)',
    eventRpt: 'New Incidents',
    eventRpt3Day: 'New incidents within past 3 days',
    inspOrder: 'Total Inspection Orders',
    pendingInspOrder: 'Outstanding Inspection Orders',
    skipInspPnt: 'Skipped Points Today',
    compInspPnt: 'Completed Inspection Points Today',
    wo: 'Total Work Orders',
    pendingWo: 'Outstanding Work Orders',
    executor: 'Inspector',
    group: 'Group',
    deleteLocation: 'The inspection point has been deleted',
    // 工单图表
    chartComp: 'Completed',
    chartInProgress: 'In Progress',
    chartonHold: 'On Hold',
    chartExecuted: 'Pending',
    chartCancel: 'Cancelled',
    // 巡检状态
    STATUS_COMP: 'Completed',
    STATUS_ARRIVE: 'Arrive',
    STATUS_NOT_STARTED: 'Not Started',
    STATUS_EVENT_RPT: 'Incident Report',
    STATUS_WO_ORDER: 'Work Order',
    STATUS_SKIP: 'Skip',
    STATUS_SKIP_REASON: 'Skip Reason',
    STATUS_ABNORMAL: 'Abnormal',
    STATUS_TIMEOUT: 'Timeout',
    STATUS_TIMEOUT_TIP: 'Long time no arrival',
    STATUS_WAIT_FOR_PROCESS: 'Wait for Process',
    STATUS_REFUSE: 'Refuse',

    FIXED: 'Fixed',
    UNPIN: 'Unpin',
    VIEW_DETAIL: 'View Detail',

    OFFLINE_TIP: 'The network connection has been disconnected, please check your network!',
    ONLINE_TIP: 'Network connection restored!',

    SEARCH_TIPS: 'Search by Inspection Order Number, Description, Inspector or Inspector Group',
    COLOR_LABEL: 'Inspection Order BG-Color',

    INSP_CUSTOM_THEME_LABEL: 'Custom Theme',
    INSP_CUSTOM_THEME_RESTORE_LABEL: 'Restore',
    INSP_STYLE_LABEL: 'Inspection Order',
    INSP_STYLE_LABEL_BG_CLR: 'Background Color',
    INSP_STYLE_LABEL_MAIN_TITLE_CLR: 'Main Title Text',
    INSP_STYLE_LABEL_SUBTITLE_CLR: 'Subtitle Text',
    INSP_STYLE_LABEL_SCROLL_BTN_BG_CLR: 'Scroll Button',
    INSP_STYLE_LABEL_SCROLL_BTN_BG_CLR_HOVER: 'Scroll Button Hover',
    INSP_STYLE_LABEL_SCROLL_BTN_ICON_CLR: 'Scroll Button Icon',
    INSP_STYLE_LABEL_SCROLL_BTN_ICON_CLR_HOVER: 'Scroll Button Icon Hover',

    INSNUM: 'Inspection Order',
    INSPLANTIME: 'Planned Inspection Time',
    REVIEWER: 'Reviewer',
    NODATA: 'No data',
    LOADING: 'Loading...',

    // 安全事件
    mapPoint: 'Inspection Point GPS',
    SAFETY_INCIDENT_TITLE: 'Safety Incident',
    SAFETY_INCIDENT_RECORDS: 'Safety Incident Records',
    SAFETY_INCIDENT_NEW_RECORDS: 'New Records',
    SAFETY_INCIDENT_NO_NEW_RECORDS: 'No new records',
    SAFETY_INCIDENT_NO_RECORDS: 'No records',
    SAFETY_INCIDENT_VIEW_ALL: 'View All Records',
    SAFETY_INCIDENT_COLLAPSE: 'Collapse',
    SAFETY_INCIDENT_START_DATE: 'Start Date',
    SAFETY_INCIDENT_END_DATE: 'End Date',
    SAFETY_INCIDENT_TIME: 'Time',
    SAFETY_INCIDENT_ACCOUNT: 'Account Name',
    SAFETY_INCIDENT_ACCOUNT_PLHD: 'Search by Account Name',

    SAFETY_INCIDENT_TYPE: 'Incident Type',
    SAFETY_INCIDENT_TYPE_ALL: 'All Types',
    SAFETY_INCIDENT_TYPE_LONGNOMOVE: 'Long Period of Inactivity',

    viewLoc: 'View Location',
    SAFETYVIEWREAD: 'Dismiss',
  },
  safetyIncident: {
    emptyTips: 'No relevant safety incident found.',
  },
  // 库存管理
  invMgt: {
    INVTMGTTITLE: 'Inventory Management',
    INVTMATTITLE: 'Inventory Material',
    MATCLEARSEARCH: 'Clear',
    MATSEARCHTIPS: 'Search by Material No. or Description',
    ADDMATERIAL: 'Add Material',
    INVTDETAIL: 'Detail',
    MATERIALNUM: 'Material No.',
    MATDESCABBR: 'Description',
    MATERIALWH: 'Warehouse',
    MATERIALQTY: 'Quantity',
    JUMPTOPAGE: 'Go to',
    JUMPTOPAGEUNIT: '',
    MATSEARCHRESULT: 'Result',
    MATEMPTYTIPS: 'No Related Records',
    MATCATEGORY: 'Material Category',
    MATSPECIFICATION1: 'Specification I',
    MATSPECIFICATION2: 'Specification II',
    MATSPECIFICATION3: 'Specification III',
    specEnterTips: 'Specification is a mandatory field',
    editSpecSuccessful: 'Edit specification successful',
    editSpecFailed: 'Edit specification failed',
    MATUNIT: 'Material Unit',
    deleteUnitSuccessful: 'Successfully deleted material unit',
    deleteUnitFailed: 'Delete material unit failed',
    deleteSuccess: 'The material has been deleted successfully!',
    MATDESC: 'Material Description',
    MATCFMADD: 'Confirm',
    MATADDEXIST: 'The material already exists',
    MATSELECTTIPS: 'Please select',
    MATSPEC1TIPS: 'E.g brand',
    MATSPEC2TIPS: 'E.g model',
    MATSPEC3TIPS: 'E.g color',
    MATUNITTIPS: 'Please enter the unit',
    matUtilMandatoryTips: 'Material Unit is a mandatory field',
    utilMandatoryTips: 'Unit is a mandatory field',
    ADDMATEMPTYTIPS: 'Mandatory field',
    MATUNITDELTIPS: 'Delete the unit?',
  },
  // 库存物料
  invMat: {
    MATRETURNTOLIST: 'Return To List',
    MATDETCLASS: 'Classification',
    MATDETUNIT: 'Unit',
    MATDETEDIT: 'Edit',
    MATDETSAVE: 'Save',
    MATDETCANCEL: 'Cancel',
    MATDETSPEC: 'Specification',
    MATDETQTY: 'Quantity',
    MATDETTOTALQTY: 'Total Quantity',
    MATDETDESC: 'Description',
    MATDETCOST: 'Avg Cost',
    INVTSTATUS: 'Inventory Status',
    INVTSEARCHTIPS: 'Search by warehouse/shelf',
    INVTRPORDERNUM: 'Receipt/Purchase Order Number',
    INVTPWPRICE: 'This Procurement/Warehousing Price',
    INVTMODIFYREASON: 'Adjustment Reason',
    INVTRPORDERNUM50WORDS: 'No more than 50 words',
    INVTMODIFYREASON50WORDS: 'No more than 200 words',

    INVTADD: 'Add Inventory',
    INVTWHNAMETIPS: 'Warehouse is a mandatory field',
    INVTWHNAMETIPSPlh: 'Please enter warehouse',
    INVTSHELF: 'Shelf',
    INVTSHELFTIPS: 'Shelf is a mandatory field',
    INVTSHELFTIPSPlh: 'Please enter shelf number',
    INVTCOST: 'Standard Cost',
    INVTEMPTYTIPS: 'No relevant inventory data found',
    INVTADDQTY: 'Quantity',
    INVTADDCFM: 'Add',
    INVTCONTINUEADD: 'Continue Add',

    INVTADJUST: 'Adjust Inventory',
    INVTADJUSTED: 'Adjusted',
    INVTADJUSTBTN: 'Adjust',
    INVTADJUSTQTY: 'Quantity Adjustment',
    INVTADJUSTCFM: 'Confirm',

    MATTISDELTIPS: 'Delete this material?',
    INVTISDELTIPS: 'Delete this inventory?',
    deleteSuccess: 'Inventory has been deleted successfully!',
    INVTDELTIPS: 'Only when the inventory quantity is equal to 0 can it be deleted',
    TOTALINVTDELTIPS: 'Only when the total quantity is equal to 0 can it be deleted',
    WH50WORDS: 'Warehouse no more than 50 words',
    SHELF50WORDS: 'Shelf no more than 50 words',
    SPECONE50WORDS: 'Specification I no more than 50 words',
    SPECTWO50WORDS: 'Specification II no more than 50 words',
    SPECTHREE50WORDS: 'Specification III no more than 50 words',
    SPEC50WORDS: 'Specification no more than 50 words',
    SPECEMPTYTIPS: 'Specification is a mandatory field',
    MATUNIT50WORDS: 'Unit no more than 50 words',
    DELWHTIPS: 'Delete the warehouse?',
    DELSHELFTIPS: 'Delete the shelf?',
    WHNOEMPTYTIPS: `The warehouse has {str} and other materials, which cannot be deleted`,
    SHELFNOEMPTYTIPS: `The shelf has {str} and other materials, which cannot be deleted`,

    MATDETISSUANCE: 'ISSUE',
    MATDETRETURN: 'RETURN',
    VIEWMATDET: 'View Items',
  },
  // 物料使用记录
  matUsageRecord: {
    title: 'Material Usage',
    ISSUETYPE_ALL: 'All',
    ISSUETYPE_ISSUE: 'Issue Item',
    ISSUETYPE_RETURN: 'Return Item',
    ISSUETYPE_AVGCSTADJ: 'Average Cost Adjustment',
    ISSUETYPE_CURBALADJ: 'Current Balance Adjustment',
    ISSUETYPE_INSERTITEM: 'New Item Inserted',
    ISSUETYPE_RECEIPT: 'Receive Item',
    MATSELECTTIPS: 'Please Select',

    MATUSEDATE: 'Date',
    MATWONUM: 'Work Order',
    MATDETAILWONUM: 'Work Order',
    MATUSESTATUS: 'Status',
    MATUSEQUANTITY: 'Quantity',

    MATUSAGERECORDDETAIL: 'Material Usage Detail',
    MATUSEDESC: 'Usage Description',
    MATUSETYPE: 'Usage Type',
    MATUSECREATEDATE: 'Usage Date',
    MATUSER: 'User',
    MATPATPNAME: 'Inspection Point',
    MATPATPNUM: 'Inspection Point No.',
    MATWHNUM: 'Warehouse No.',
    MATSHELFNAME: 'Shelf',
    MATSHELFNUM: 'Shelf No.',

    MAT_ISSUE: 'Issue Item',
    MAT_BEFORE_ISSUE: 'Quantity Before Adjustment',
    MAT_AFTER_ISSUE: 'Quantity After Adjustment',

    MAT_RETURN: 'Return Item',
    MAT_BEFORE_RETURN: 'Quantity Before Adjustment',
    MAT_AFTER_RETURN: 'Quantity After Adjustment',

    MAT_AVGCSTADJ: 'Average Cost Adjustment',
    MAT_BEFORE_AVGCSTADJ: 'Quantity Before Adjustment',
    MAT_AFTER_AVGCSTADJ: 'Quantity After Adjustment',

    MAT_CURBALADJ: 'Current Balance Adjustment',
    MAT_BEFORE_CURBALADJ: 'Quantity Before Adjustment',
    MAT_AFTER_CURBALADJ: 'Quantity After Adjustment',

    MAT_INSERTITEM: 'New Item Inserted',
    MAT_BEFORE_INSERTITEM: 'Quantity Before Adjustment',
    MAT_AFTER_INSERTITEM: 'Quantity After Adjustment',

    MAT_RECEIPT: 'Receive Item',
    MAT_BEFORE_RECEIPT: 'Quantity Before Adjustment',
    MAT_AFTER_RECEIPT: 'Quantity After Adjustment',

    MATCOST: 'Cost Changes',
    MATNEWCOST: 'New Average Costs',
    MATOLDCOST: 'Old Average Costs',
    emptyTips: 'No relevant material usage found.',
  },
  rentPropertyList: {
    title: 'Lendable Asset List',
    enterSearch: 'Search by Item Name',
    name: 'Item Name',
    nameTips: 'Item Name is a mandatory field',
    labelType: 'Label Type',
    labelTypePlh: 'Select Label Type',
    labelTypeTips: 'Label Type is a mandatory field',
    qrcode: 'QR Code',
    lastDate: 'Last Transaction Date',
    status: 'Status',
    statusPlh: 'Select Status',
    lent: 'Lent',
    available: 'Available',
    changeToAvailable: 'Change to Available',
    lost: 'Lost',
    stop: 'Disable',
    stopped: 'Disabled',
    photo: 'Photo',
    createTitle: 'Create Item',
    editTitle: 'Edit Item',
    detailTitle: 'Item Details',
    confirmDelete: 'Confirm to delete the item?',
    createSuccess: 'The Item has been created successfully!',
    editSuccess: 'The Item has been edited successfully!',
    deleteSuccess: 'The Item has been deleted successfully!',
    stopSuccess: 'The Item has been disabled successfully!',
    enableSuccess: 'The Item has been changed to available successfully!',
    emptyTips: 'No relevant item found.',
  },
  selectRentalProperty: {
    title: 'Item List',
    selected: 'Selected {count}',
    selectedProperty: 'Selected Items',
    selectTips: 'Please select item',
  },
  lendReturn: {
    title: 'Lend / Return',
    enterSearch: 'Search by Item Name',
    num: 'No.',
    name: 'Item Name',
    nameTips: 'Item Name is a mandatory field',
    labelType: 'Label Type',
    lendPurpose: 'Lend Reason',
    lendPurposePlh: 'Please enter or select the lend reason',
    lendPurposeTips: 'Lend Reason is a mandatory field',
    lendReason: 'Lend Reason',
    setCommonPurpose: 'Set as Frequently Used Reason',
    propertyPhoto: 'Item Photo',
    transactionDate: 'Transaction Date',
    transactionDateTips: 'Transaction Date is a mandatory field',
    selectDate: 'Select Date',
    expectedReturnDate: 'Expected Return Date',
    expectedReturnDateTips: 'Expected Return Date is a mandatory field',
    editReturnDateReason: 'Reason for modification',
    editReasonPh: 'Enter the reason for modifying the expected return date',
    editReasonTips: 'Reason for modification is a mandatory field',
    recipient: 'Recipient',
    recipientTips: 'Recipient is a mandatory field',
    recipientPlh: 'Please enter or select the recipient',
    selectRecipientTitle: 'Recipient List',
    recipientId: 'Recipient ID',
    recipientIdTips: 'Recipient ID is a mandatory field',
    recipientPhone: "Recipient's Phone",
    recipientPhoneTips: "Recipient's Phone is a mandatory field",
    handler: 'Handler',
    handlerTips: 'Handler is a mandatory field',
    handlerId: 'Handler ID',
    handlerIdTips: 'Handler ID is a mandatory field',
    handlerIdRuleTips: 'Only digits, letters, or special symbols. Special symbols only #-',
    returnStatus: 'Status',
    toBeReturned: 'Lent',
    returned: 'Returned',
    return: 'Return',
    confirmReturnTips: 'Confirm to Return?',
    returnSuccess: 'The Item has been returned successfully!',
    createTitle: 'Create Lend Order',
    editTitle: 'Edit Lend Order',
    detailTitle: 'Lend Order Details',
    continueAdd: 'Continue Add',
    createSuccess: 'The Lend Order has been created successfully!',
    editSuccess: 'The Lend Order has been edited successfully!',
    emptyTips: 'No relevant lend order found.',
    maxLentTime: 'Maximum lend duration',
    day: 'Day(s)',
    hour: 'Hour(s)',
    maxLentTimeUnitTips: 'Please select time unit',
    maxLentTimeUnitPlh: 'Select time unit',
  },
  disposalOrder: {
    title: 'Disposal Order',
    reason: 'Reason for Disposal',
    reasonPlh: 'Please enter or select the reason for disposal',
    reasonTips: 'Reason for Disposal is a mandatory field',
    disposalReason: 'Reason for Disposal',
    rentalOrder: 'Lend Order',
    confirmer: 'Confirmer',
    confirmerTips: 'Confirmer is a mandatory field',
    selectConfirmerTitle: 'Confirmer List',
    attachment: 'Attachment',
    disposalPhoto: 'Disposal Order Photo',
    status: 'Status',
    confirmed: 'Confirmed',
    unconfirmed: 'Unconfirmed',
    cancelled: 'Cancelled',
    createTitle: 'Create Disposal Order',
    editTitle: 'Edit Disposal Order',
    detailTitle: 'Disposal Order Details',
    cancelDisposalOrder: 'Cancel Disposal Order',
    cancelDisposalOrderTips: 'Please confirm whether to cancel disposal order?',
    cancelDisposalOrderSuccess: 'The Disposal Order has been cancelled successfully!',
    confirmDisposalOrder: 'Confirm Disposal Order',
    confirmDisposalOrderTips: 'Please confirm whether to confirm disposal order?',
    confirmDisposalOrderSuccess: 'The Disposal Order has been confirmed successfully!',
    confirmTime: 'Confirm Time',
    cancelTime: 'Cancel Time',
    createSuccess: 'The Disposal Order has been created successfully!',
    editSuccess: 'The Disposal Order has been edited successfully!',
    emptyTips: 'No relevant disposal order found.',
    attachmentUploadFaild: 'Attachment upload failed!',
    isExistLendOrder: 'This item has created a lend order, No.: {num}',
    attachmentTypeTips: 'Only .jpg, .jpeg, .png, .pdf format files can be uploaded',
  },
  patrolTrack: {
    inspectionPointList: 'Inspection Point List',
    track: 'Track',
  },
  guide: {
    previous: 'Previous',
    next: 'Next',
    goOpen: 'GO',
    goCreate: 'GO',
    goPreview: 'Go Preview',
    goView: 'Go View',
    goEnable: 'Go Enable',
    goSet: 'GO',
    goAdd: 'GO',
    finish: 'Finish',
    ok: 'OK',
    understand: 'Understand',
    continueCreate: 'Continue Create',
    nextStage: 'Next Stage',
  }
}

export default lang